import { action as Action, createReducer } from 'typesafe-actions';
import { List } from 'immutable';

import { PessoaFisicaBaseActions, PessoaFisicaBaseTypes } from './types';
import { IPessoaFisicaBaseState, INITIAL_STATE } from './model';

export const pessoaFisicaBaseCreators = {
  clear: (): PessoaFisicaBaseActions => Action(PessoaFisicaBaseTypes.CLEAR),
  fetchAsync: (page?: number): PessoaFisicaBaseActions =>
    Action(PessoaFisicaBaseTypes.FETCH_ASYNC, { page }),
  fetchSuccess: (
    page: number,
    totalFetchedPessoaFisica: number,
  ): PessoaFisicaBaseActions =>
    Action(PessoaFisicaBaseTypes.FETCH_SUCCESS, {
      page,
      totalFetchedPessoaFisica,
    }),
  fetchFail: (): PessoaFisicaBaseActions =>
    Action(PessoaFisicaBaseTypes.FETCH_FAIL),
  setFetchedPessoaFisicaIDs: (
    pessoaFisicaIDs: ReadonlyArray<string>,
  ): PessoaFisicaBaseActions =>
    Action(PessoaFisicaBaseTypes.SET_FETCHED_PESSOA_FISICA_ID, {
      pessoaFisicaIDs,
    }),
};

const pessoaFisicaBaseReducer = createReducer<
  IPessoaFisicaBaseState,
  PessoaFisicaBaseActions
>(INITIAL_STATE)
  .handleType(PessoaFisicaBaseTypes.CLEAR, () => INITIAL_STATE)
  .handleType(PessoaFisicaBaseTypes.FETCH_ASYNC, (state) => ({
    ...state,
    finalizationStatus: 'fetching',
  }))
  .handleType(PessoaFisicaBaseTypes.FETCH_SUCCESS, (state, action) => ({
    ...state,
    finalizationStatus: 'success',
    currentPage: action.payload.page,
    totalFetchedPessoaFisica: action.payload.totalFetchedPessoaFisica,
  }))
  .handleType(PessoaFisicaBaseTypes.FETCH_FAIL, (state) => ({
    ...state,
    finalizationStatus: 'fail',
  }))
  .handleType(
    PessoaFisicaBaseTypes.SET_FETCHED_PESSOA_FISICA_ID,
    (state, action) => ({
      ...state,
      fetchedPessoaFisicaIDs: List(action.payload.pessoaFisicaIDs),
    }),
  );

export default pessoaFisicaBaseReducer;
