import { IPessoaFisicaRecord } from './models/pessoa-fisica';

export enum PessoaFisicaTypes {
  CLEAR = '@PESSOA_FISICA_CORE/CLEAR',
  REMOVE = '@PESSOA_FISICA_CORE/REMOVE',
  UPDATE = '@PESSOA_FISICA_CORE/UPDATE',
  ADD = '@PESSOA_FISICA_CORE/ADD',
}

// Action Types

interface ClearAction {
  type: typeof PessoaFisicaTypes.CLEAR;
}

interface AddAction {
  type: typeof PessoaFisicaTypes.ADD;
  payload: {
    pessoaFisica: IPessoaFisicaRecord;
  };
}

interface RemoveAction {
  type: typeof PessoaFisicaTypes.REMOVE;
  payload: {
    pessoaFisicaID: string;
  };
}

interface UpdateAction {
  type: typeof PessoaFisicaTypes.UPDATE;
  payload: {
    pessoaFisica: IPessoaFisicaRecord[];
  };
}

export type PessoaFisicaCoreActions =
  | ClearAction
  | AddAction
  | RemoveAction
  | UpdateAction;
