import React, { useState, useCallback, useEffect } from 'react';
import { FaAngleUp } from 'react-icons/fa';
import { useLocation } from 'react-router-dom';

import {
  Container,
  Content,
  RightContainer,
  IconContainer,
  Text,
  List,
  Pipe,
} from './styles';

interface MenuListProps {
  title: string;
  icon: React.ReactNode;
  children: React.ReactNode;
  onClick?: any;
  route: string;
}

const MenuList: React.FC<MenuListProps> = ({
  title,
  icon,
  children,
  onClick,
  route,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [isSamePath, setIsSamePath] = useState(false);
  const { pathname } = useLocation();

  const handleClick = useCallback(() => {
    setIsOpen((currState) => !currState);
  }, []);

  useEffect(() => {
    if (pathname.split('/')[1] === route) {
      setIsSamePath(true);
    } else {
      setIsSamePath(false);
    }
  }, [pathname, route]);

  return (
    <Container onClick={onClick}>
      <Pipe isActive={isSamePath} />
      <Content onClick={handleClick}>
        <RightContainer isActive={isSamePath || isOpen}>
          {icon}
          <Text>{title}</Text>
        </RightContainer>
        <IconContainer isOpen={isOpen} isActive={isSamePath || isOpen}>
          <FaAngleUp />
        </IconContainer>
      </Content>
      <List isOpen={isOpen}>{children}</List>
    </Container>
  );
};

export default React.memo(MenuList);
