import { combineReducers } from 'redux';

import { baseReducer, baseCreators, baseSagas, baseSelectors } from './base';
import {
  filterReducer,
  filterCreators,
  filterSagas,
  filterSelectors,
} from './filter';
import { formReducer, formCreators, formSagas, formSelectors } from './form';

export const pessoaFisicaCreators = {
  base: baseCreators,
  filter: filterCreators,
  form: formCreators,
};

export const pessoaFisicaSelectors = {
  base: baseSelectors,
  filter: filterSelectors,
  form: formSelectors,
};

export const pessoaFisicaSagas = [...baseSagas, ...filterSagas, ...formSagas];

export default combineReducers({
  base: baseReducer,
  filter: filterReducer,
  form: formReducer,
});
