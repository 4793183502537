function cleanEmptyValues<T>(obj: T): T {
  const object = Object.entries(obj).reduce(
    // eslint-disable-next-line eqeqeq
    (a, [k, v]) =>
      v === null || v === '' || v === undefined ? a : { ...a, [k]: v },
    {},
  );
  return object as T;
}

export default cleanEmptyValues;
