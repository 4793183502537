import React from 'react';
import { Map } from 'immutable';

import {
  FaAngleUp,
  FaAngleDown,
  FaArrowDown,
  FaCaretUp,
  FaCaretDown,
  FaCog,
  FaCubes,
  FaDelicious,
  FaFileInvoiceDollar,
  FaLock,
  FaMoneyBillWave,
  FaPlus,
  FaPrint,
  FaRegFileAlt,
  FaRegAddressCard,
  FaSearch,
  FaSkating,
  FaTrash,
  FaUserAlt,
  FaUserTie,
  FaUserEdit,
  FaUserPlus,
  FaWarehouse,
  FaFile,
  FaEye,
  FaProductHunt,
  FaPercent,
} from 'react-icons/fa';
import {
  MdAddCircleOutline,
  MdExtension,
  MdGavel,
  MdPeople,
  MdLibraryAdd,
  MdAddBox,
  MdBusiness,
  MdPersonAdd,
} from 'react-icons/md';
import { GrLineChart, GrUserManager } from 'react-icons/gr';
import { GiFiles } from 'react-icons/gi';
import {
  RiUserSettingsLine,
  RiCustomerService2Line,
  RiProductHuntLine,
  RiExchangeDollarLine,
} from 'react-icons/ri';
import { IoIosPeople } from 'react-icons/io';

interface IconProps {
  icon: string;
}

const Icon: React.FC<IconProps> = ({ icon }) => {
  const fontAwesomeIcons = Map({
    FaAngleUp: <FaAngleUp />,
    FaAngleDown: <FaAngleDown />,
    FaCaretUp: <FaCaretUp />,
    FaCaretDown: <FaCaretDown />,
    FaCog: <FaCog />,
    FaCubes: <FaCubes />,
    FaDelicious: <FaDelicious />,
    FaFileInvoiceDollar: <FaFileInvoiceDollar />,
    FaLock: <FaLock />,
    FaMoneyBillWave: <FaMoneyBillWave />,
    FaPlus: <FaPlus />,
    FaPrint: <FaPrint />,
    FaRegFileAlt: <FaRegFileAlt />,
    FaRegAddressCard: <FaRegAddressCard />,
    FaSearch: <FaSearch />,
    FaTrash: <FaTrash />,
    FaUserAlt: <FaUserAlt />,
    FaUserTie: <FaUserTie />,
    FaUserEdit: <FaUserEdit />,
    FaUserPlus: <FaUserPlus />,
    FaWarehouse: <FaWarehouse />,
    FaFile: <FaFile />,
    FaEye: <FaEye />,
    RiUserSettingsLine: <RiUserSettingsLine />,
    GrLineChart: <GrLineChart />,
    MdLibraryAdd: <MdLibraryAdd />,
    MdAddBox: <MdAddBox />,
    MdBusiness: <MdBusiness />,
    MdPersonAdd: <MdPersonAdd />,
    GiFiles: <GiFiles />,
    IoIosPeople: <IoIosPeople />,
    GrUserManager: <GrUserManager />,
    RiCustomerService2Line: <RiCustomerService2Line />,
    RiProductHuntLine: <RiProductHuntLine />,
    FaProductHunt: <FaProductHunt />,
    FaPercent: <FaPercent />,
    RiExchangeDollarLine: <RiExchangeDollarLine />,
    'fa-arrow-down': <FaArrowDown />,
    'fa-skating': <FaSkating />,
  });

  const materialDesignIcons = Map({
    MdAddCircleOutline: <MdAddCircleOutline />,
    MdExtension: <MdExtension />,
    MdGavel: <MdGavel />,
    MdPeople: <MdPeople />,
  });
  const icons = fontAwesomeIcons.merge(materialDesignIcons);

  return <>{icons.has(icon) ? icons.get(icon) : null}</>;
};

export default Icon;
