import React from 'react';

import { Container } from './styles';
import Logo from '@assets/fake.jpeg';

const FakeScreen: React.FC = () => {
  return <Container>
    <img alt="fake" src={Logo}/>
  </Container>
}

export default FakeScreen;