import { action as Action, createReducer } from 'typesafe-actions';
import {
  PessoaFisicaFormActions,
  PessoaFisicaFormTypes,
  PessoaFisicaFormDTO,
} from './types';
import { INITIAL_STATE, IPessoaFisicaFormState, IRequestStatus } from './model';

export const pessoaFisicaFormCreators = {
  clear: (): PessoaFisicaFormActions => Action(PessoaFisicaFormTypes.CLEAR),
  savePessoaFisicaAsync: (
    data: Partial<PessoaFisicaFormDTO>,
  ): PessoaFisicaFormActions =>
    Action(PessoaFisicaFormTypes.SAVE_PESSOA_FISICA_ASYNC, { data }),
  savePessoaFisicaSuccess: (): PessoaFisicaFormActions =>
    Action(PessoaFisicaFormTypes.SAVE_PESSOA_FISICA_SUCCESS),
  updatePessoaFisicaAsync: (
    data: Partial<PessoaFisicaFormDTO>,
    pessoaFisicaID: string,
  ): PessoaFisicaFormActions =>
    Action(PessoaFisicaFormTypes.UPDATE_PESSOA_FISICA_ASYNC, {
      data,
      pessoaFisicaID,
    }),
  removePessoaFisicaAsync: (pessoaFisicaID: string): PessoaFisicaFormActions =>
    Action(PessoaFisicaFormTypes.REMOVE_PESSOA_FISICA_ASYNC, {
      pessoaFisicaID,
    }),
  setPessoaFisicaID: (pessoaFisicaID: string): PessoaFisicaFormActions =>
    Action(PessoaFisicaFormTypes.SET_PESSOA_FISICA_ID, { pessoaFisicaID }),
  requestFinished: (status: IRequestStatus): PessoaFisicaFormActions =>
    Action(PessoaFisicaFormTypes.SET_FINALIZATION_SAVE_STATUS, { status }),
};

const pessoaFisicaFormReducer = createReducer<
  IPessoaFisicaFormState,
  PessoaFisicaFormActions
>(INITIAL_STATE)
  .handleType(PessoaFisicaFormTypes.CLEAR, () => INITIAL_STATE)
  .handleType(PessoaFisicaFormTypes.SAVE_PESSOA_FISICA_ASYNC, (state) => ({
    ...state,
    isLoading: true,
    createFinalizationStatus: 'progress',
  }))
  .handleType(PessoaFisicaFormTypes.UPDATE_PESSOA_FISICA_ASYNC, (state) => ({
    ...state,
    isLoading: true,
  }))
  .handleType(PessoaFisicaFormTypes.REMOVE_PESSOA_FISICA_ASYNC, (state) => ({
    ...state,
    isLoading: true,
  }))
  .handleType(
    PessoaFisicaFormTypes.SET_FINALIZATION_SAVE_STATUS,
    (state, action) => ({
      ...state,
      status: action.payload.status,
      isLoading: false,
    }),
  )
  .handleType(PessoaFisicaFormTypes.SET_PESSOA_FISICA_ID, (state, action) => ({
    ...state,
    selectedPessoaFisicaID: action.payload.pessoaFisicaID,
  }))
  .handleType(PessoaFisicaFormTypes.SAVE_PESSOA_FISICA_SUCCESS, (state) => ({
    ...state,
    isLoading: false,
    createFinalizationStatus: 'success',
  }));

export default pessoaFisicaFormReducer;
