import { List } from 'immutable';

export type FinalizationStatus = 'pristine' | 'success' | 'fail' | 'fetching';

export interface IPessoaFisicaBaseState {
  readonly currentPage: number;
  readonly totalFetchedPessoaFisica: number;
  readonly finalizationStatus: FinalizationStatus;
  readonly limit: number;
  readonly isSortingReverse: boolean;
  readonly fetchedPessoaFisicaIDs: List<string>;
}

export const INITIAL_STATE: IPessoaFisicaBaseState = {
  currentPage: 1,
  totalFetchedPessoaFisica: 0,
  finalizationStatus: 'pristine',
  limit: 10,
  isSortingReverse: false,
  fetchedPessoaFisicaIDs: List(),
};
