import { action as Action, createReducer } from 'typesafe-actions';

import {
  UpdatePasswordActions,
  UpdatePasswordTypes,
  IPayloadRequestAsyncAction,
} from './types';
import { INITIAL_STATE, IUpdatePasswordState } from './model';

export const updatePasswordCreators = {
  requestAsync: ({
    oldPassword,
    password,
    userID,
  }: IPayloadRequestAsyncAction): UpdatePasswordActions =>
    Action(UpdatePasswordTypes.REQUEST_ASYNC, {
      oldPassword,
      password,
      userID,
    }),
  requestSuccess: (): UpdatePasswordActions =>
    Action(UpdatePasswordTypes.REQUEST_SUCCESS),
  requestFail: (): UpdatePasswordActions =>
    Action(UpdatePasswordTypes.REQUEST_FAIL),
};

const updatePasswordReducer = createReducer<
  IUpdatePasswordState,
  UpdatePasswordActions
>(INITIAL_STATE)
  .handleType(UpdatePasswordTypes.REQUEST_ASYNC, (state) => ({
    ...state,
    loading: true,
  }))
  .handleType(UpdatePasswordTypes.REQUEST_SUCCESS, (state) => ({
    ...state,
    loading: false,
  }))
  .handleType(UpdatePasswordTypes.REQUEST_FAIL, (state) => ({
    ...state,
    loading: false,
  }));

export default updatePasswordReducer;
