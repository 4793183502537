import { call, put, takeLatest } from 'redux-saga/effects';
import { toast } from 'react-toastify';

import { FetchQueryAsyncAction, FilterSectionTypes } from './types';
import { filterSectionCreator } from './duck';
import { fetchPessoaFisicaByNameOrCpf } from '../../core/pessoa-fisica/sagas';
import { pessoaFisicaBaseCreators } from '../base/duck';

function* watchFetchManpowerByNameOrCpf({ payload }: FetchQueryAsyncAction) {
  try {
    const pessoaFisica = yield call(
      fetchPessoaFisicaByNameOrCpf,
      payload.query,
      payload.page,
    );
    const pessoaFisicaIDs: string[] = pessoaFisica.gcpf.map((gcpf) =>
      String(gcpf.id),
    );
    yield put(
      filterSectionCreator.fetchSuccess(
        pessoaFisica.page,
        pessoaFisica.total,
        pessoaFisicaIDs,
      ),
    );
  } catch (er) {
    yield put(pessoaFisicaBaseCreators.fetchFail());
    console.log({ er });
    if (er.response.status === 404) {
      toast.error('Pessoa física não encontrada');
      return;
    }
    toast.error('Falha ao carregar pessoa física');
  }
}

export default [
  takeLatest(
    FilterSectionTypes.FETCH_QUERY_ASYNC,
    watchFetchManpowerByNameOrCpf,
  ),
];
