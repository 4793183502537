import { ApplicationState } from '../../index';
import { createSelector } from '../../../class/memoize/createSelector';

const getState = (state: ApplicationState) => state.gcpf.filter;

const getQuery = createSelector([getState], (state) => state.query);

const getCurrentPage = createSelector([getState], (state) => state.currentPage);

const getFetchedFilteredPessoaFisicaIDs = createSelector(
  [getState],
  (state) => state.fetchedPessoaFisicaIDs,
);

const getTotalFilteredPessoaFisica = createSelector(
  [getState],
  (state) => state.totalFilteredPessoaFisica,
);

export default Object.freeze({
  getQuery,
  getFetchedFilteredPessoaFisicaIDs,
  getTotalFilteredPessoaFisica,
  getCurrentPage,
});
