import styled from 'styled-components';
import { Link as LinkTemplate } from 'react-router-dom';

interface IContainerProps {
  isOpen: boolean;
}

export const Container = styled.div<IContainerProps>`
  position: absolute;
  opacity: ${({ isOpen }) => (isOpen ? 1 : 0)};
  z-index: ${({ isOpen }) => (isOpen ? 2 : -1)};
  transition: opacity 0.4s ease;
  flex-direction: column;
  top: 2rem;
  left: 0;
  width: 180px;
  height: 147px;
  border-radius: 0.25rem;
  border: 1px solid #e9ecef;
  background-color: #fff;
`;

export const Content = styled.div`
  display: flex;
  align-items: center;
  padding: 15px 0;
`;

export const Link = styled(LinkTemplate)`
  text-decoration: none;
  flex: 1;
  display: flex;
  align-items: center;
  padding: 10px 15px;
  color: ${({ theme }) => theme.semantic.dark};
  &:hover {
    background-color: #f8f9fa;
  }
`;

export const Section = styled.div`
  border-bottom: 1px solid #e9ecef;
`;
