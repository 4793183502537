import React from 'react';
import { FiAlertTriangle } from 'react-icons/fi';

import {
  Modal,
  Typography,
  Card,
  CardContent,
  Container,
  Box,
} from '@material-ui/core';

import orange from '@material-ui/core/colors/orange';

import { ButtonMaterial } from '@components/buttons';
import { useStyles } from './styles';

type WarningModalProps = {
  isOpen: boolean;
  onClose: any;
  onConfirm: any;
  message: string;
};

const WarningModal: React.FC<WarningModalProps> = ({
  isOpen,
  onClose,
  onConfirm,
  message,
}) => {
  const classes = useStyles();
  return (
    <Modal open={isOpen} onClose={onClose} className={classes.root}>
      <Container className={classes.root}>
        <Card raised className={classes.content}>
          <CardContent>
            {/* <Typography variant="h3" align="center">
              <FiAlertTriangle size={150} color={orange[300]} />
            </Typography> */}
            {/* <Typography variant="h5" align="center">
              Tem Certeza?
            </Typography> */}
            <Typography
              variant="body1"
              align="center"
              className={classes.margin}
            >
              {message}
            </Typography>
            <Box className={classes.wrapper}>
              <ButtonMaterial
                className={classes.margin}
                label="Sim"
                color="primary"
                variant="contained"
                onClick={onConfirm}
              />
              <ButtonMaterial
                className={classes.margin}
                label="Não"
                color="secondary"
                variant="contained"
                onClick={onClose}
              />
            </Box>
          </CardContent>
        </Card>
      </Container>
    </Modal>
  );
};

export default WarningModal;
