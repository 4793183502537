import React, { useRef, useCallback } from 'react';
import { FaCaretDown } from 'react-icons/fa';

import { Wrapper, AvatarWrapper, Avatar, WrapperRow, Text } from './styles';

interface IAvatarSectionProps {
  userName: string;
  handleFileInput: any;
  avatar: string;
  slotSettings: React.ReactNode;
  onOpenSettingsSection?: (e: React.MouseEvent) => void;
}

type Ref = HTMLDivElement;

const AvatarSection: React.ForwardRefRenderFunction<
  Ref,
  IAvatarSectionProps
> = (
  { handleFileInput, userName, avatar, onOpenSettingsSection, slotSettings },
  ref,
) => {
  const inputFileRef = useRef<HTMLInputElement>(null);

  const handleInputChange = useCallback(
    (e: React.ChangeEvent) => {
      e.preventDefault();
      handleFileInput(inputFileRef.current?.files);
    },
    [handleFileInput],
  );

  return (
    <Wrapper>
      <AvatarWrapper
        onClick={() => {
          inputFileRef.current?.click();
        }}
      >
        <Avatar src={avatar} />
        <input
          type="file"
          id="avatar"
          ref={inputFileRef}
          onChange={handleInputChange}
        />
      </AvatarWrapper>
      <WrapperRow onClick={onOpenSettingsSection} tabIndex={0} ref={ref}>
        <Text>{userName}</Text>
        <FaCaretDown size={18} color="#8d97ad" />
        {slotSettings}
      </WrapperRow>
    </Wrapper>
  );
};

export default React.forwardRef(AvatarSection);
