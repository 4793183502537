import { takeLatest, put, call } from 'redux-saga/effects';
import { authCreators } from './duck';
import { AuthTypes, AuthSignInRequestAction } from './types';

import api from '../../../services/api';

function* signIn({ payload }: AuthSignInRequestAction): Generator {
  try {
    const { email, password } = payload;
    localStorage.setItem('email', email);

    const response: any = yield call(api.post, '/auth/login', {
      email,
      password,
    });

    const { accessToken, userEmail, userID, role, userName } = response.data;
    yield put(
      authCreators.signInSuccess(
        accessToken,
        userEmail,
        userID,
        role,
        userName,
      ),
    );
    api.defaults.headers.Authorization = `Bearer ${accessToken}`;
  } catch (er) {
    localStorage.setItem('showMessageLogin', '1');
    yield put(authCreators.signInFailure());
  }
}

// function signOut() {
//   console.log('logout');
// }

function setToken({ payload }: any) {
  if (!payload) return;

  const { token } = payload.auth;
  if (token) {
    api.defaults.headers.Authorization = `Bearer ${token}`;
  }
}

export default [
  takeLatest('persist/REHYDRATE', setToken),
  takeLatest(AuthTypes.SIGN_IN_REQUEST, signIn),
  // takeLatest(AuthTypes.SIGN_OUT, signOut),
];
