import Immutable, { Map, Record } from 'immutable';

import {
  IPessoaJuridicaTelefoneRecord,
  PessoaJuridicaTelefone,
} from './pessoa-juridica-telefone';
import {
  IPessoaJuridicaEnderecoRecord,
  PessoaJuridicaEnderecoRecord,
  fromJSON as fromJSONEndereco,
} from './pessoa-juridica-endereco';
import {
  IPessoaJuridicaEmailRecord,
  PessoaJuridicaEmailRecord,
} from './pessoa-juridica-email';
import {
  IPessoaJuridicaContaRecord,
  PessoaJuridicaContaRecord,
  fromJSON as fromJSONConta,
} from './pessoa-juridica-conta';

export interface IPessoaJuridica {
  readonly id: string;
  readonly nome_fantasia: string;
  readonly razao_social: string;
  readonly cnpj: string;
  readonly ie: string;
  readonly ie_isenta: boolean;
  readonly dt_abertura: Date;
  readonly cliente: boolean;
  readonly parceiro: boolean;
  readonly fornecedor: boolean;
  readonly conta: IPessoaJuridicaContaRecord;
  readonly email: IPessoaJuridicaEmailRecord;
  readonly endereco: IPessoaJuridicaEnderecoRecord;
  readonly telefone: IPessoaJuridicaTelefoneRecord;
}

const defaultValues: IPessoaJuridica = {
  id: '',
  nome_fantasia: '',
  razao_social: '',
  cnpj: '',
  ie: '',
  ie_isenta: false,
  dt_abertura: new Date(),
  cliente: false,
  parceiro: true,
  fornecedor: false,
  conta: new PessoaJuridicaContaRecord(),
  email: new PessoaJuridicaEmailRecord(),
  endereco: new PessoaJuridicaEnderecoRecord(),
  telefone: new PessoaJuridicaTelefone(),
};

export interface IPessoaJuridicaRecord
  extends Record<IPessoaJuridica>,
    IPessoaJuridica {}

export class PessoaJuridica extends Record(defaultValues) {}

export type IPessoaJuridicaByPessoaJuridicaID = Immutable.Map<
  string,
  IPessoaJuridicaRecord
>;

export type IPessoaJuridicaState = {
  pessoaJuridicaByPessoaJuridicaID: IPessoaJuridicaByPessoaJuridicaID;
};

export const INITIAL_STATE: IPessoaJuridicaState = {
  pessoaJuridicaByPessoaJuridicaID: Map({}),
};

export const fromJSON = (json) => {
  const id = json.id !== null ? String(json.id) : defaultValues.id;
  const nome_fantasia =
    json.nome_fantasia !== null
      ? json.nome_fantasia
      : defaultValues.nome_fantasia;
  const razao_social =
    json.razao_social !== null ? json.razao_social : defaultValues.razao_social;
  const cnpj = json.cnpj !== null ? json.cnpj : defaultValues.cnpj;
  const ie = json.ie !== null ? json.ie : defaultValues.ie;
  const ie_isenta =
    json.ie_isenta !== null ? json.ie_isenta : defaultValues.ie_isenta;
  const dt_abertura =
    json.dt_abertura !== null ? json.dt_abertura : defaultValues.dt_abertura;
  const cliente = json.cliente !== null ? json.cliente : defaultValues.cliente;
  const parceiro =
    json.parceiro !== null ? json.parceiro : defaultValues.parceiro;
  const fornecedor =
    json.fornecedor !== null ? json.fornecedor : defaultValues.fornecedor;
  const conta =
    json.conta !== null ? fromJSONConta(json.conta) : defaultValues.conta;
  const endereco =
    json.endereco !== null
      ? fromJSONEndereco(json.endereco)
      : defaultValues.endereco;

  const telefone =
    json.telefone !== null ? json.telefone : defaultValues.telefone;
  const email = json.email !== null ? json.email : defaultValues.email;

  return new PessoaJuridica({
    id,
    nome_fantasia,
    razao_social,
    cnpj,
    ie,
    ie_isenta,
    dt_abertura,
    cliente,
    parceiro,
    fornecedor,
    conta,
    endereco,
    telefone,
    email,
  });
};
