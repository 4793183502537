import { Record } from 'immutable';

import { EnumPessoaFisicaStatus } from './types';

export interface IPessoaFisicaEmail {
  readonly id: string;
  readonly id_gcpf: string;
  readonly email: string;
  readonly status: EnumPessoaFisicaStatus;
}

const defaultValues: IPessoaFisicaEmail = {
  id: '',
  id_gcpf: '',
  email: '',
  status: EnumPessoaFisicaStatus.INVALIDO,
};

export interface IPessoaFisicaEmailRecord
  extends Record<IPessoaFisicaEmail>,
    IPessoaFisicaEmail {}

export class PessoaFisicaEmailRecord extends Record(defaultValues) {}
