import { getPessoaFisicaByPessoaFisicaIDs as getAllPessoaFisica } from '../../core/pessoa-fisica/selectors';
import { ApplicationState } from '../../index';
import { createSelector } from '../../../class/memoize/createSelector';
import { cpfMask } from '../../../../utils/cpf-mask';

export const getState = (state: ApplicationState) => state.gcpf.base;

export const getCurrentPage = createSelector(
  [getState],
  (state) => state.currentPage,
);

export const getTotalFetchedPessoaFisica = createSelector(
  [getState],
  (state) => state.totalFetchedPessoaFisica,
);

export const getFetchedPessoaFisicaIDs = createSelector(
  [getState],
  (state) => state.fetchedPessoaFisicaIDs,
);

export const getPessoaFisicaDTOByPessoaFisicaID = createSelector(
  [getAllPessoaFisica],
  (allPessoaFisica) =>
    allPessoaFisica.map((pessoaFisica) => ({
      id: pessoaFisica.id,
      nome: pessoaFisica.nome,
      cpf: cpfMask(pessoaFisica.cpf),
      email: pessoaFisica.email.email,
    })),
);

export default Object.freeze({
  getCurrentPage,
  getFetchedPessoaFisicaIDs,
  getPessoaFisicaDTOByPessoaFisicaID,
  getTotalFetchedPessoaFisica,
});
