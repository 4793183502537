/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useCallback, useRef } from 'react';
import { NavLink } from 'react-router-dom';

import { useTheme } from '@hooks';

import { Container, IconContainer } from './styles';

import useOutsideClick from '../../../../hooks/useOutsideClick';

interface MenuItemProps {
  icon: React.ReactNode;
  route: string;
  label: string;
  isActive: boolean;
  handleClick(e: React.MouseEvent): void;
}

const MenuItem: React.FC<MenuItemProps> = ({
  route,
  label,
  handleClick,
  isActive,
}) => {
  const theme = useTheme();
  const wrapperRef = useRef(null);
  const [lastClicked, setLastClicked] = useState(false);
  const onClick = useCallback(
    (event) => {
      handleClick(event);
      setLastClicked((currState) => !currState);
    },
    [handleClick],
  );
  const handleLastClicked = useCallback(() => {
    setLastClicked(false);
  }, []);
  useOutsideClick(wrapperRef, handleLastClicked);

  return (
    <Container onClick={onClick} ref={wrapperRef}>
      <IconContainer lastClick={lastClicked} isActive={isActive} />
      <NavLink
        to={route}
        style={{
          display: 'inline-block',
          margin: '-6px',
          textDecoration: 'none',
          color: '#8d97ad',
          fontWeight: 'normal',
          padding: '7px',
          paddingLeft: '15px',
          borderRadius: '5px',
          width: '90%',
        }}
        activeStyle={{
          textDecoration: 'none',
          color: '#2E6FB2',
          backgroundColor: '#BEE3F8',
        }}
      >
        {label}
      </NavLink>
    </Container>
  );
};

export default React.memo(MenuItem);
