import { Record } from 'immutable';

export interface IPessoaFisicaTelefone {
  readonly id: string;
  readonly id_gcpf: string;
  readonly ddd: string;
  readonly telefone: string;
  readonly ddd_2?: string;
  readonly telefone_2?: string;
}

const defaultValues: IPessoaFisicaTelefone = {
  id: '',
  id_gcpf: '',
  ddd: '',
  telefone: '',
};

export interface IPessoaFisicaTelefoneRecord
  extends Record<IPessoaFisicaTelefone>,
    IPessoaFisicaTelefone {}

export class PessoaFisicaTelefone extends Record(defaultValues) {}
