import { IPessoaJuridicaRecord } from './models/pessoa-juridica';

export enum PessoaJuridicaTypes {
  CLEAR = '@PESSOA_JURIDICA_CORE/CLEAR',
  REMOVE = '@PESSOA_JURIDICA_CORE/REMOVE',
  UPDATE = '@PESSOA_JURIDICA_CORE/UPDATE',
  ADD = '@PESSOA_JURIDICA_CORE/ADD',
}

// Action Types

interface ClearAction {
  type: typeof PessoaJuridicaTypes.CLEAR;
}

interface AddAction {
  type: typeof PessoaJuridicaTypes.ADD;
  payload: {
    pessoaJuridica: IPessoaJuridicaRecord;
  };
}

interface RemoveAction {
  type: typeof PessoaJuridicaTypes.REMOVE;
  payload: {
    pessoaJuridicaID: string;
  };
}

interface UpdateAction {
  type: typeof PessoaJuridicaTypes.UPDATE;
  payload: {
    pessoaJuridica: IPessoaJuridicaRecord[];
  };
}

export type PessoaJuridicaCoreActions =
  | ClearAction
  | AddAction
  | RemoveAction
  | UpdateAction;
