import React from 'react';

import { Container } from './styles';

interface ButtonHamburger {
  onClick(e: React.MouseEvent): void;
  color?: string;
}

const ButtonHamburger: React.FC<ButtonHamburger> = ({ onClick, color }) => (
  <Container color="primary" onClick={onClick}>
    <span style={{ fontSize: '1.5rem', color: 'rgba(255,255,255,.5)' }}>
      &#9776;
    </span>
  </Container>
);

export default ButtonHamburger;
