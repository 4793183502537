import { call, put } from 'redux-saga/effects';

import api from '@api';

import { pessoaJuridicaCoreCreators } from './duck';
import { IPessoaJuridica, fromJSON } from './models/pessoa-juridica';

export function* updatePessoaJuridica(gcpj: IPessoaJuridica[]) {
  const pessoaJuridicaRecord = gcpj.map(fromJSON);
  yield put(pessoaJuridicaCoreCreators.update(pessoaJuridicaRecord));
  return pessoaJuridicaRecord;
}

export function* fetchPessoaJuridica(page = 1) {
  const response = yield call(api.get, `/gcpj?page=${page}`);
  yield updatePessoaJuridica(response.data.gcpj);

  return response.data;
}

export function* fetchPessoaJuridicaByCnpjOrFantasyName(
  query: string,
  page = 1,
) {
  const response = yield call(
    api.get,
    `/gcpj/cnpjOrFantasyName?page=${page}&cnpjOrFantasyName=${query}`,
  );
  const gcpj = response.data.gcpj.map((gcpjs) => fromJSON(gcpjs));
  yield put(pessoaJuridicaCoreCreators.update(gcpj));
  // yield put(suppliersBaseCreators.fetchSuccess());
  return response.data;
}
