export default {
  semantic: {
    primary: '#007DFE',
    secondary: '#6c757d',
    silverwhite: '#C6C6C6',
    success: '#28a745',
    info: '#17a2b8',
    warning: '#ffc107',
    danger: '#dc3545',
    light: '#f8f9fa',
    white: '#ffffff',
    dark: '#2b2b2b',
    darkblue: '#007DFE',
  },
  colors: {
    primaryMain: '#90caf9',
    primaryDark: '#648dae',
    primaryLight: '#a6d4fa',
    secondaryMain: '#f48fb1',
    secondaryDark: '#aa647b',
    secondaryLight: '#f6a5c0',
    dangerMain: '#f44336',
    dangerDark: '#d32f2f',
    dangerLight: '#e57373',
    warningMain: '#ff9800',
    warningDark: '#f57c00',
    warningLight: '#ffb74d',
    infoMain: '#2196f3',
    infoDark: '#1976d2',
    infoLight: '#64b5f6',
    successMain: '#4caf50',
    successDark: '#388e3c',
    successLight: '#81c784',
  },
  text: {
    colors: {
      primary: '#03a9f3',
      secondary: '#6c757d',
      success: '#28a745',
      info: '#17a2b8',
      warning: '#ffc107',
      danger: '#dc3545',
      light: '#f8f9fa',
      dark: '#343a40',
      body: '#212529',
      muted: '#6c757d',
      logo: '#fd5a5c',
    },
    hover: {
      primary: '#0056b3',
      secondary: '#494f54',
      success: '#19692c',
      info: '#0f6674',
      warning: '#ba8b00',
      danger: '#a71d2a',
      light: '#cbd3da',
      dark: '#121416',
    },
  },
  focus: {
    danger: '0 0 0 0.2rem rgba(220, 53, 69, 0.25)',
    primary: '0 0 0 0.2rem rgba(38, 143, 255, 0.5)',
  },
};
