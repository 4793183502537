import { takeLatest, call, put } from 'redux-saga/effects';
import { toast } from 'react-toastify';

import { fetchPessoaFisica } from '../../core/pessoa-fisica/sagas';

import { FetchAsyncAction, PessoaFisicaBaseTypes } from './types';
import { pessoaFisicaBaseCreators } from './duck';

function* watchPessoaFisicaFetchAsync({ payload }: FetchAsyncAction) {
  try {
    const response = yield call(fetchPessoaFisica, payload.page);

    yield put(
      pessoaFisicaBaseCreators.fetchSuccess(response.page, response.total),
    );
    const pessoaFisicaIDs = response.gcpf.map((gcpfRow) => String(gcpfRow.id));

    yield put(
      pessoaFisicaBaseCreators.setFetchedPessoaFisicaIDs(pessoaFisicaIDs),
    );
  } catch (err) {
    console.log(err);
    toast.error('Falha ao carregar Pessoa Física');
    yield put(pessoaFisicaBaseCreators.fetchFail());
  }
}

export default [
  takeLatest(PessoaFisicaBaseTypes.FETCH_ASYNC, watchPessoaFisicaFetchAsync),
];
