import { call, put } from 'redux-saga/effects';

import api from '@api';

import { pessoaFisicaCoreCreators } from './duck';
import { IPessoaFisica, fromJSON } from './models/pessoa-fisica';

export function* updatePessoaFisica(gcpf: IPessoaFisica[]) {
  const pessoaFisicaRecord = gcpf.map(fromJSON);
  yield put(pessoaFisicaCoreCreators.update(pessoaFisicaRecord));
  return pessoaFisicaRecord;
}

export function* fetchPessoaFisica(page = 1) {
  const response = yield call(api.get, `/gcpf?page=${page}`);
  yield updatePessoaFisica(response.data.gcpf);

  return response.data;
}

export function* fetchPessoaFisicaByNameOrCpf(query: string, page = 1) {
  const response = yield call(
    api.get,
    `/gcpf/nameOrCpf?page=${page}&nameOrCpf=${query}`,
  );
  const gcpf = response.data.gcpf.map((gcpfs) => fromJSON(gcpfs));
  yield put(pessoaFisicaCoreCreators.update(gcpf));
  // yield put(suppliersBaseCreators.fetchSuccess());
  return response.data;
}
