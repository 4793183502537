import styled from 'styled-components';

export const Container = styled.ul`
  padding: 10px;
  width: 100%;
  height: 100%;
  font-size: 14px;
  white-space: nowrap;
  overflow-y: scroll;
  ::-webkit-scrollbar {
    width: 0px;
  }
`;

export const Logo = styled.img`
  width: 60%;
  height: auto;
  margin: 0 auto;
  padding: 20px;
`;
