import React from 'react';

import { Container } from './styles';

interface MenuWrapperProps {
  children: React.ReactNode;
  slotAvatarSection: React.ReactNode;
}

const MenuWrapper: React.FC<MenuWrapperProps> = ({
  children,
  slotAvatarSection,
}) => {
  return (
    <Container>
      {slotAvatarSection}
      {children}
    </Container>
  );
};

export default React.memo(MenuWrapper);
