import { Record } from 'immutable';

import { EnumPessoaJuridicaStatus } from './types';

export interface IPessoaJuridicaEmail {
  readonly id: string;
  readonly id_gcpj: string;
  readonly email: string;
  readonly status: EnumPessoaJuridicaStatus;
}

const defaultValues: IPessoaJuridicaEmail = {
  id: '',
  id_gcpj: '',
  email: '',
  status: EnumPessoaJuridicaStatus.INVALIDO,
};

export interface IPessoaJuridicaEmailRecord
  extends Record<IPessoaJuridicaEmail>,
    IPessoaJuridicaEmail {}

export class PessoaJuridicaEmailRecord extends Record(defaultValues) {}
