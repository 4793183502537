import { List } from 'immutable';

export type FinalizationStatus = 'pristine' | 'success' | 'fail' | 'fetching';

export interface IPessoaJuridicaBaseState {
  readonly currentPage: number;
  readonly totalFetchedPessoaJuridica: number;
  readonly finalizationStatus: FinalizationStatus;
  readonly limit: number;
  readonly isSortingReverse: boolean;
  readonly fetchedPessoaJuridicaIDs: List<string>;
}

export const INITIAL_STATE: IPessoaJuridicaBaseState = {
  currentPage: 1,
  totalFetchedPessoaJuridica: 0,
  finalizationStatus: 'pristine',
  limit: 10,
  isSortingReverse: false,
  fetchedPessoaJuridicaIDs: List(),
};
