import React, { lazy } from 'react';

import { Switch } from 'react-router-dom';

import ClientesPfDetails from 'src/screens/clientes-pf/detalhes';
import EmpresaCDC from 'src/screens/empresa-cdc';
import CadastroEmpresaConsorcio from 'src/screens/empresa-consorcio';
import FakeScreen from 'src/screens/fake-screen';
import CadastroEmpresaCDC from '../screens/empresa-cdc/form';
import NoLayout from './no-layout';
import PrivateRoutes from './private-routes';

const Home = lazy(() => import('../screens/home'));
const Login = lazy(() => import('../screens/login'));

const CreatePassword = lazy(() => import('../screens/create-password/index'));
const ChangePassword = lazy(() => import('../screens/change-password/index'));
const ForgotPassword = lazy(() => import('../screens/forgot-password/index'));

const Agencia = lazy(() => import('../screens/agencia'));
const AgenciaRegister = lazy(() =>
  import('../screens/agencia/form-agencia/CreateAgenciaForm'),
);
const AgenciaUpdate = lazy(() =>
  import('../screens/agencia/form-agencia/EditFormAgencia'),
);

const ChavesJ = lazy(() => import('../screens/chaves-j'));
const ChavesJRegister = lazy(() => import('../screens/chaves-j/form'));

const Convenios = lazy(() => import('../screens/convenios'));
const ConveniosRegister = lazy(() => import('../screens/convenios/form'));

const ConveniosGrupos = lazy(() => import('../screens/convenios-grupos'));
const ConveniosGruposRegister = lazy(() =>
  import('../screens/convenios-grupos/form'),
);

const Periodos = lazy(() => import('../screens/periodos'));
const PeriodosRegister = lazy(() =>
  import('../screens/periodos/form-periodos/CreatePeriodosForm'),
);
const PeriodosUpdate = lazy(() =>
  import('../screens/periodos/form-periodos/EditFormPeriodos'),
);

const Empresas = lazy(() => import('../screens/empresas'));
const EmpresasRegister = lazy(() =>
  import('../screens/empresas/form-empresas/CreateEmpresasForm'),
);
const EmpresasUpdate = lazy(() =>
  import('../screens/empresas/form-empresas/EditEmpresasForm'),
);

const Linhas = lazy(() => import('../screens/linhas'));
const LinhasRegister = lazy(() =>
  import('../screens/linhas/form-linhas/CreateLinhasForm'),
);
const LinhasUpdate = lazy(() =>
  import('../screens/linhas/form-linhas/EditLinhasForm'),
);

const Regional = lazy(() => import('../screens/regional'));
const RegionalRegister = lazy(() =>
  import('../screens/regional/form/CreateRegionalForm'),
);
const RegionalUpdate = lazy(() =>
  import('../screens/regional/form/EditFormRegional'),
);

/* Pessoa Física */
const PessoaFisica = lazy(() => import('../screens/pessoa-fisica'));
const PessoaFisicaRegister = lazy(() =>
  import('../screens/pessoa-fisica/create-pessoa-fisica'),
);
const PessoaFisicaUpdate = lazy(() =>
  import('../screens/pessoa-fisica/update-pessoa-fisica'),
);

/* Pessoa Jurídica */
const PessoaJuridica = lazy(() => import('../screens/pessoa-juridica'));
const PessoaJuridicaRegister = lazy(() =>
  import('../screens/pessoa-juridica/create-pessoa-juridica'),
);
const PessoaJuridicaUpdate = lazy(() =>
  import('../screens/pessoa-juridica/update-pessoa-juridica'),
);

/* Relatórios */
const RelatorioCdc = lazy(() => import('../screens/relatorios/relatorio-cdc'));
const RelatorioConsorcio = lazy(() =>
  import('../screens/relatorios/relatorio-consorcio'),
);

const Retorno = lazy(() => import('../screens/retorno'));
const Consorcio = lazy(() => import('../screens/consorcio'));
const FormEditarPerfil = lazy(() => import('../screens/perfil'));
const ConsorcioDetalhes = lazy(() => import('../screens/consorcio/details'));

const CreditoPf = lazy(() => import('../screens/credito-pf'));
const CreditoPFDetails = lazy(() =>
  import('../screens/credito-pf/grid-credito-pf/details'),
);

const ClientesPf = lazy(() => import('../screens/clientes-pf'));

const Routes = () => (
  <>
    <Switch>
      <NoLayout exact path="/login" component={Login} />
      <NoLayout exact path="/forgot-password" component={ForgotPassword} />
      <NoLayout
        exact
        path="/create-password/:token"
        component={CreatePassword}
      />
      <PrivateRoutes
        exact
        path="/dashboard"
        component={Home}
        roles={['ADMIN', 'FUNCIONARIO', 'GERENTE']}
      />
      <PrivateRoutes
        exact
        path="/change-password/:userID"
        component={ChangePassword}
        roles={['ADMIN', 'FUNCIONARIO', 'GERENTE']}
      />
      {/* Pessoa física */}
      <PrivateRoutes
        exact
        path="/cadastros/pessoa-fisica"
        component={PessoaFisica}
        roles={['ADMIN', 'FUNCIONARIO', 'GERENTE']}
      />
      <PrivateRoutes
        exact
        path="/cadastros/pessoa-fisica/create"
        component={PessoaFisicaRegister}
        roles={['ADMIN', 'FUNCIONARIO']}
      />
      <PrivateRoutes
        exact
        path="/cadastros/pessoa-fisica/update/:pessoaFisicaID"
        component={PessoaFisicaUpdate}
        roles={['ADMIN', 'FUNCIONARIO']}
      />
      <PrivateRoutes
        exact
        path="/cadastros-operacional/agencia"
        component={Agencia}
        roles={['ADMIN', 'FUNCIONARIO']}
      />
      <PrivateRoutes
        exact
        path="/cadastros-operacional/agencia/create"
        component={AgenciaRegister}
        roles={['ADMIN', 'FUNCIONARIO']}
      />
      <PrivateRoutes
        exact
        path="/cadastros-operacional/agencia/update/:id"
        component={AgenciaUpdate}
        roles={['ADMIN', 'FUNCIONARIO']}
      />
      <PrivateRoutes
        exact
        path="/cadastros/chaves-j"
        component={ChavesJ}
        roles={['ADMIN', 'FUNCIONARIO']}
      />
      <PrivateRoutes
        exact
        path="/cadastros/chaves-j/form"
        component={ChavesJRegister}
        roles={['ADMIN', 'FUNCIONARIO']}
      />
      <PrivateRoutes
        exact
        path="/cadastros/convenios"
        component={Convenios}
        roles={['ADMIN', 'FUNCIONARIO']}
      />
      <PrivateRoutes
        exact
        path="/cadastros/convenios/form"
        component={ConveniosRegister}
        roles={['ADMIN', 'FUNCIONARIO']}
      />
      <PrivateRoutes
        exact
        path="/cadastros/convenios-grupos"
        component={ConveniosGrupos}
        roles={['ADMIN', 'FUNCIONARIO']}
      />
      <PrivateRoutes
        exact
        path="/cadastros/convenios-grupos/form"
        component={ConveniosGruposRegister}
        roles={['ADMIN', 'FUNCIONARIO']}
      />
      <PrivateRoutes
        exact
        path="/cadastros-operacional/periodos"
        component={Periodos}
        roles={['ADMIN', 'FUNCIONARIO']}
      />
      <PrivateRoutes
        exact
        path="/cadastros-operacional/periodos/create"
        component={PeriodosRegister}
        roles={['ADMIN', 'FUNCIONARIO']}
      />
      <PrivateRoutes
        exact
        path="/cadastros-operacional/periodos/update/:id"
        component={PeriodosUpdate}
        roles={['ADMIN', 'FUNCIONARIO']}
      />
      <PrivateRoutes
        exact
        path="/cadastro-empresa/empresas"
        component={Empresas}
        roles={['ADMIN']}
      />
      <PrivateRoutes
        exact
        path="/cadastro-empresa/empresas/create"
        component={EmpresasRegister}
        roles={['ADMIN']}
      />
      <PrivateRoutes
        exact
        path="/cadastro-empresa/empresas/update/:id"
        component={EmpresasUpdate}
        roles={['ADMIN']}
      />
      <PrivateRoutes
        exact
        path="/cadastro-regional/regional"
        component={Regional}
        roles={['ADMIN', 'FUNCIONARIO']}
      />
      <PrivateRoutes
        exact
        path="/cadastro-regional/regional/create"
        component={RegionalRegister}
        roles={['ADMIN', 'FUNCIONARIO']}
      />
      <PrivateRoutes
        exact
        path="/cadastro-regional/regional/update/:id"
        component={RegionalUpdate}
        roles={['ADMIN', 'FUNCIONARIO']}
      />

      {/* Linhas de Crédito */}
      <PrivateRoutes
        exact
        path="/cadastros-operacional/linhas"
        component={Linhas}
        roles={['ADMIN', 'FUNCIONARIO']}
      />
      <PrivateRoutes
        exact
        path="/cadastros-operacional/linhas/create"
        component={LinhasRegister}
        roles={['ADMIN', 'FUNCIONARIO']}
      />
      <PrivateRoutes
        exact
        path="/cadastros-operacional/linhas/update/:id"
        component={LinhasUpdate}
        roles={['ADMIN', 'FUNCIONARIO']}
      />
      {/* Pessoa juridica */}
      <PrivateRoutes
        exact
        path="/cadastros/pessoa-juridica"
        component={PessoaJuridica}
        roles={['ADMIN', 'FUNCIONARIO']}
      />
      <PrivateRoutes
        exact
        path="/cadastros/pessoa-juridica/create"
        component={PessoaJuridicaRegister}
        roles={['ADMIN', 'FUNCIONARIO']}
      />
      <PrivateRoutes
        exact
        path="/cadastros/pessoa-juridica/update/:pessoaJuridicaID"
        component={PessoaJuridicaUpdate}
        roles={['ADMIN', 'FUNCIONARIO']}
      />
      {/* Retorno */}
      <PrivateRoutes
        exact
        path="/cadastros-operacional/retorno"
        component={Retorno}
        roles={['ADMIN', 'FUNCIONARIO']}
      />
      {/* Relatórios */}
      <PrivateRoutes
        exact
        path="/relatorios/relatorio-cdc"
        component={RelatorioCdc}
        roles={['ADMIN', 'FUNCIONARIO']}
      />
      <PrivateRoutes
        exact
        path="/relatorios/relatorio-consorcio"
        component={RelatorioConsorcio}
        roles={['ADMIN', 'FUNCIONARIO']}
      />
      <PrivateRoutes
        exact
        path="/modulos/sub-modulo/sub-sub-modulo/relatorio-consorcio"
        component={RelatorioConsorcio}
        roles={['ADMIN', 'FUNCIONARIO']}
      />
      {/* Perfil */}
      <PrivateRoutes
        exact
        path="/editar-perfil"
        component={FormEditarPerfil}
        roles={['ADMIN', 'FUNCIONARIO']}
      />
      {/* Crédito PF */}
      <PrivateRoutes
        exact
        path="/produtos/credito-pf"
        component={CreditoPf}
        roles={['ADMIN', 'FUNCIONARIO']}
      />
      <PrivateRoutes
        exact
        path="/cadastros/credito-pf/detalhes/:id"
        component={CreditoPFDetails}
        roles={['ADMIN', 'FUNCIONARIO']}
      />
      {/* Consorcio */}
      <PrivateRoutes
        exact
        path="/produtos/consorcio"
        component={Consorcio}
        roles={['ADMIN', 'FUNCIONARIO']}
      />
      <PrivateRoutes
        exact
        path="/modulos/consorcio/detalhes"
        component={ConsorcioDetalhes}
        roles={['ADMIN', 'FUNCIONARIO']}
      />
      {/* Clientes PF */}
      <PrivateRoutes
        exact
        path="/clientes/clientes-pf"
        component={ClientesPf}
        roles={['ADMIN', 'FUNCIONARIO']}
      />
      <PrivateRoutes
        exact
        path="/modulos/clientes-pf/detalhes/:id"
        component={ClientesPfDetails}
        roles={['ADMIN', 'FUNCIONARIO']}
      />
      {/* Empresa  cdc */}
      <PrivateRoutes
        exact
        path="/taxas/cadastro-remuneracao/empresa-cdc"
        component={CadastroEmpresaCDC}
        roles={['ADMIN', 'FUNCIONARIO']}
      />
      <PrivateRoutes
        exact
        path="/taxas/empresa-cdc"
        component={EmpresaCDC}
        roles={['ADMIN', 'FUNCIONARIO']}
      />
      {/* Empresa  consorcio */}
      <PrivateRoutes
        exact
        path="/taxas/cadastro-remuneracao/empresa-consorcio"
        component={CadastroEmpresaConsorcio}
        roles={['ADMIN', 'FUNCIONARIO']}
      />
      <PrivateRoutes
        exact
        path="*"
        component={FakeScreen}
        roles={['ADMIN', 'FUNCIONARIO']}
      />
    </Switch>
  </>
);

export default Routes;
