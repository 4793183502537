import { IRequestStatus } from './model';

export enum PessoaJuridicaFormTypes {
  CLEAR = '@PESSOA_JURIDICA_FORM/CLEAR',
  SAVE_PESSOA_JURIDICA_ASYNC = '@PESSOA_JURIDICA_FORM/SAVE_PESSOA_FISICA_ASYNC',
  SAVE_PESSOA_JURIDICA_SUCCESS = '@PESSOA_JURIDICA_FORM/SAVE_PESSOA_FISICA_SUCCESS',
  UPDATE_PESSOA_JURIDICA_ASYNC = '@PESSOA_JURIDICA_FORM/UPDATE_PESSOA_FISICA_ASYNC',
  REMOVE_PESSOA_JURIDICA_ASYNC = '@PESSOA_JURIDICA_FORM/REMOVE_PESSOA_FISICA_ASYNC',
  SET_PESSOA_JURIDICA_ID = '@PESSOA_JURIDICA_FORM/SET_PESSOA_FISICA_ID',
  SET_FINALIZATION_SAVE_STATUS = '@PESSOA_JURIDICA_FORM/SET_FINALIZATION_SAVE_STATUS',
}

interface ClearAction {
  type: typeof PessoaJuridicaFormTypes.CLEAR;
}
interface SavePessoaJuridicaSuccessAction {
  type: typeof PessoaJuridicaFormTypes.SAVE_PESSOA_JURIDICA_SUCCESS;
}

export interface SavePessoaJuridicaAsyncAction {
  type: typeof PessoaJuridicaFormTypes.SAVE_PESSOA_JURIDICA_ASYNC;
  payload: {
    data: Partial<PessoaJuridicaFormDTO>;
  };
}

interface SetPessoaJuridicaIDAction {
  type: typeof PessoaJuridicaFormTypes.SET_PESSOA_JURIDICA_ID;
  payload: {
    pessoaJuridicaID: string;
  };
}

interface SetFinalizationSaveStatusAction {
  type: typeof PessoaJuridicaFormTypes.SET_FINALIZATION_SAVE_STATUS;
  payload: {
    status: IRequestStatus;
  };
}

export interface UpdateRequestAsyncAction {
  type: typeof PessoaJuridicaFormTypes.UPDATE_PESSOA_JURIDICA_ASYNC;
  payload: {
    data: Partial<PessoaJuridicaFormDTO>;
    pessoaJuridicaID: string;
  };
}

export interface RemoveRequestAsyncAction {
  type: typeof PessoaJuridicaFormTypes.REMOVE_PESSOA_JURIDICA_ASYNC;
  payload: {
    pessoaJuridicaID: string;
  };
}

export type PessoaJuridicaFormActions =
  | ClearAction
  | SavePessoaJuridicaAsyncAction
  | SetPessoaJuridicaIDAction
  | SetFinalizationSaveStatusAction
  | UpdateRequestAsyncAction
  | RemoveRequestAsyncAction
  | SavePessoaJuridicaSuccessAction;

type TipoCadastroValue = 'fornecedor' | 'cliente' | 'parceiro';

export type TipoCadastroType = {
  value: TipoCadastroValue;
  label: string;
};

export interface PessoaJuridicaFormDTO {
  razao_social: string;
  nome_fantasia: string;
  dt_abertura: Date;
  cnpj: string;
  ie?: string;
  ie_isenta?: boolean;
  tipo_cadastro: TipoCadastroType[];
  id_banco: {
    label: string;
    value: number | string;
  };
  agencia: string;
  agencia_dv: string;
  conta: string;
  conta_dv: string;
  operacao?: string;
  cep: string;
  logradouro: string;
  numero: string;
  complemento: string;
  cidade: string;
  uf: string;
  email: string;
  ddd: string;
  telefone: string;
  ddd_2?: string;
  telefone_2?: string;
  id_logradouro: number;
  id_uf: number;
  id_cidade: number;
}
