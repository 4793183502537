import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';

import { MenuList } from '@components/dashboard/menu';
import Icon from '@components/icons';
import { userSelectors } from '@store';

import MenuItemWired from './menu-item-wired';
import { Item } from '../../../../../nav';

interface MenuListWiredProps {
  title: string;
  icon: string;
  list: Item[];
  checkRoute: string;
}

const { getLoggedUserRole } = userSelectors;

const MenuWired: React.FC<MenuListWiredProps> = ({
  title,
  icon,
  list,
  checkRoute,
}) => {
  const roleUser = useSelector(getLoggedUserRole);

  const renderedList = useMemo(() => {
    return list.map((item) => {
      if (item.roles.find((role) => role === roleUser)) {
        if (item.subMenu === undefined) {
          return (
            <MenuItemWired
              key={item.route}
              label={item.label}
              icon={<Icon icon={item.icon} />}
              route={item.route}
            />
          );
        }
        return (
          <MenuWired
            key={item.label}
            title={item.label}
            icon={item.icon}
            list={item.subMenu}
            checkRoute={item.checkRoute!}
          />
        );
      }
      return <></>;
    });
  }, [list, roleUser]);

  return (
    <MenuList title={title} icon={<Icon icon={icon} />} route={checkRoute}>
      {renderedList}
    </MenuList>
  );
};

export default React.memo(MenuWired);
