import React from 'react';
import TemplateScreen from '@components/template-screen';
import { Paper, Typography } from '@material-ui/core';
import { useStyles } from './styles';
// import { Container } from './styles';

import {
  Controller,
  useForm
} from 'react-hook-form';

import { TextField } from '@material-ui/core';
import { ButtonLoaderMaterial } from '@components/buttons';
import { ButtonWrapper } from './styles';


const CadastroEmpresaConsorcio: React.FC = () => {

  const classes = useStyles();

  const {
    errors,
    control,
  } = useForm();

  return (
    <TemplateScreen title="Cadastro remuneração empresa Consórcio">
      <Paper className={classes.paper} elevation={0} style={{ padding: '20px' }}>
        <Typography variant="h6" >
        Cadastro Remuneração Consórcio
        </Typography>

        <Controller
          as={TextField}
          control={control}
          error={!!errors.nome}
          name="segmento"
          label="Segmento"
          placeholder="Insira o código do segmento ou nome"
          fullWidth
          margin="normal"
        />

       

        <div style={{ display: 'flex' }}>
        <Controller
          as={TextField}
          control={control}
          error={!!errors.nome}
          name="metaInicial"
          label="Meta Inicial"
          placeholder="Insira a meta Inicial"
          fullWidth
          margin="normal"
          style={{ marginRight: '50px' }}
        />
          <Controller
            as={TextField}
            control={control}
            error={!!errors.nome}
            name="metaFinal"
            label="Meta Final"
            placeholder="Insira a meta final"
            fullWidth
            margin="normal"
          />
        </div>

        <div style={{ display: 'flex' }}>
          <Controller
            as={TextField}
            control={control}
            error={!!errors.nome}
            name="prazoInicial"
            label="Prazo Inicial"
            placeholder="Insira o prazo inicial"
            fullWidth
            margin="normal"
            style={{ marginRight: '50px' }}
          />
          <Controller
            as={TextField}
            control={control}
            error={!!errors.nome}
            name="prazoFial"
            label="Prazo final"
            placeholder="Insira o prazo final"
            fullWidth
            margin="normal"
          />
        </div>

        <div style={{ display: 'flex' }}>
          <Controller
            as={TextField}
            control={control}
            error={!!errors.nome}
            name="remuneracaoTaxa1"
            label="Remuneração Taxa por parcela"
            placeholder="Insira a taxa por parcela"
            fullWidth
            margin="normal"
            style={{ marginRight: '50px' }}
          />
          <Controller
            as={TextField}
            control={control}
            error={!!errors.nome}
            name="taxaTotal"
            label="Remuneração Taxa Total"
            placeholder="Insira a taxa total"
            fullWidth
            margin="normal"
          />
        </div>

        <div style={{ display: 'flex' }}>
          <Controller
            as={TextField}
            control={control}
            error={!!errors.nome}
            name="inicioVigencia"
            placeholder="Inicio vigência"
            label="Inicio vigência"
            fullWidth
            margin="normal"
            style={{ marginRight: '50px' }}
          />
          <Controller
            as={TextField}
            control={control}
            error={!!errors.nome}
            name="fimvingencia"
            label="Fim vigência"
            placeholder="Fim vigência"
            fullWidth
            margin="normal"
          />
        </div>

        <ButtonWrapper>
          <ButtonLoaderMaterial
            type="submit"
            label="Salvar"
            variant="contained"
            color="primary"
            loading={false}
          />
        </ButtonWrapper>
      </Paper>
    </TemplateScreen>
  );
}

export default CadastroEmpresaConsorcio;