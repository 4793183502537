import { createSelector } from '../../../class/memoize/createSelector';
import { ApplicationState } from '../../index';

const getState = (state: ApplicationState) => state.core.pessoaJuridica;

export const getPessoaJuridicaByPessoaJuridicaIDs = createSelector(
  [getState],
  (state) => state.pessoaJuridicaByPessoaJuridicaID,
);

export default Object.freeze({});
