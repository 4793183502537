import React, { useContext, useCallback } from 'react';

import { Container, MenuAside, Opacity } from './styles';

import { TemplateContext } from '../context';
import Menu from './menu';

const Aside = () => {
  const { state, dispatch } = useContext(TemplateContext);
  const closeMenu = useCallback(() => {
    if (state.showAside) {
      dispatch({ type: 'HIDE_ASIDE' });
    }
  }, [dispatch, state.showAside]);
  return (
    <Container isOpen={state.showAside}>
      <MenuAside>
        <Menu />
      </MenuAside>
      <Opacity isOpen={state.showAside} onClick={closeMenu} />
    </Container>
  );
};

export default React.memo(Aside);
