import { getPessoaJuridicaByPessoaJuridicaIDs as getAllPessoaJuridica } from '../../core/pessoa-juridica/selectors';
import { ApplicationState } from '../../index';
import { createSelector } from '../../../class/memoize/createSelector';

export const getState = (state: ApplicationState) => state.gcpj.base;

export const getCurrentPage = createSelector(
  [getState],
  (state) => state.currentPage,
);

export const getTotalFetchedPessoaJuridica = createSelector(
  [getState],
  (state) => state.totalFetchedPessoaJuridica,
);

export const getFetchedPessoaJuridicaIDs = createSelector(
  [getState],
  (state) => state.fetchedPessoaJuridicaIDs,
);

export const getPessoaJuridicaDTOByPessoaJuridicaID = createSelector(
  [getAllPessoaJuridica],
  (allPessoaJuridica) =>
    allPessoaJuridica.map((pessoaJuridica) => ({
      id: pessoaJuridica.id,
      nome_fantasia: pessoaJuridica.nome_fantasia,
      cnpj: pessoaJuridica.cnpj,
      email: pessoaJuridica.email.email,
    })),
);

export default Object.freeze({
  getCurrentPage,
  getFetchedPessoaJuridicaIDs,
  getPessoaJuridicaDTOByPessoaJuridicaID,
  getTotalFetchedPessoaJuridica,
});
