import { takeLatest, call, put } from 'redux-saga/effects';
import api from '@api';
import { toast } from 'react-toastify';
import { RequestAsyncAction, CreatePasswordTypes } from './types';
import { createPasswordCreators } from './duck';

function* createPassword({ payload }: RequestAsyncAction) {
  try {
    const { token, password } = payload;
    yield call(api.post, '/user-password', { user_token: token, password });
    yield put(createPasswordCreators.requestSuccess());
    toast.success('Senha criada com sucesso! Volte e efetue o login.');
  } catch (err) {
    yield put(createPasswordCreators.requestFail());
    toast.error('Token inválido');
  }
}

export default [takeLatest(CreatePasswordTypes.REQUEST_ASYNC, createPassword)];
