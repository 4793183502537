import { action as Action, createReducer } from 'typesafe-actions';
import { List } from 'immutable';

import { PessoaJuridicaBaseActions, PessoaJuridicaBaseTypes } from './types';
import { IPessoaJuridicaBaseState, INITIAL_STATE } from './model';

export const pessoaJuridicaBaseCreators = {
  clear: (): PessoaJuridicaBaseActions => Action(PessoaJuridicaBaseTypes.CLEAR),
  fetchAsync: (page?: number): PessoaJuridicaBaseActions =>
    Action(PessoaJuridicaBaseTypes.FETCH_ASYNC, { page }),
  fetchSuccess: (
    page: number,
    totalFetchedPessoaJuridica: number,
  ): PessoaJuridicaBaseActions =>
    Action(PessoaJuridicaBaseTypes.FETCH_SUCCESS, {
      page,
      totalFetchedPessoaJuridica,
    }),
  fetchFail: (): PessoaJuridicaBaseActions =>
    Action(PessoaJuridicaBaseTypes.FETCH_FAIL),
  setFetchedPessoaJuridicaIDs: (
    pessoaJuridicaIDs: ReadonlyArray<string>,
  ): PessoaJuridicaBaseActions =>
    Action(PessoaJuridicaBaseTypes.SET_FETCHED_PESSOA_JURIDICA_ID, {
      pessoaJuridicaIDs,
    }),
};

const pessoaJuridicaBaseReducer = createReducer<
  IPessoaJuridicaBaseState,
  PessoaJuridicaBaseActions
>(INITIAL_STATE)
  .handleType(PessoaJuridicaBaseTypes.CLEAR, () => INITIAL_STATE)
  .handleType(PessoaJuridicaBaseTypes.FETCH_ASYNC, (state) => ({
    ...state,
    finalizationStatus: 'fetching',
  }))
  .handleType(PessoaJuridicaBaseTypes.FETCH_SUCCESS, (state, action) => ({
    ...state,
    finalizationStatus: 'success',
    currentPage: action.payload.page,
    totalFetchedPessoaJuridica: action.payload.totalFetchedPessoaJuridica,
  }))
  .handleType(PessoaJuridicaBaseTypes.FETCH_FAIL, (state) => ({
    ...state,
    finalizationStatus: 'fail',
  }))
  .handleType(
    PessoaJuridicaBaseTypes.SET_FETCHED_PESSOA_JURIDICA_ID,
    (state, action) => ({
      ...state,
      fetchedPessoaJuridicaIDs: List(action.payload.pessoaJuridicaIDs),
    }),
  );

export default pessoaJuridicaBaseReducer;
