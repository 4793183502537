export enum ForgotPasswordTypes {
  REQUEST_ASYNC = '@FORGOT_PASSWORD/REQUEST_ASYNC',
  REQUEST_SUCCESS = '@FORGOT_PASSWORD/REQUEST_SUCCESS',
  REQUEST_FAIL = '@FORGOT_PASSWORD/REQUEST_FAIL',
}

// Action Types

export type IPayloadRequestAsyncAction = {
  userEmail: string;
};

export interface RequestAsyncAction {
  type: typeof ForgotPasswordTypes.REQUEST_ASYNC;
  payload: IPayloadRequestAsyncAction;
}

interface RequestSuccessAction {
  type: typeof ForgotPasswordTypes.REQUEST_SUCCESS;
}

interface RequestFailAction {
  type: typeof ForgotPasswordTypes.REQUEST_FAIL;
}

export type ForgotPasswordActions =
  | RequestAsyncAction
  | RequestSuccessAction
  | RequestFailAction;
