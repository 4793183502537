import { Record } from 'immutable';
import { ICidade, CidadeRecord } from '../../models/endereco/cidade';
import { IUf, UfRecord } from '../../models/endereco/uf';
import {
  ILogradouro,
  LogradouroRecord,
} from '../../models/endereco/logradouro';

export interface IPessoaFisicaEndereco {
  readonly id: string;
  readonly numero: string;
  readonly complemento?: string;
  readonly cidade: ICidade;
  readonly uf: IUf;
  readonly logradouro: ILogradouro;
}

const defaultValues: IPessoaFisicaEndereco = {
  id: '',
  numero: '',
  complemento: '',
  cidade: new CidadeRecord(),
  uf: new UfRecord(),
  logradouro: new LogradouroRecord(),
};

export interface IPessoaFisicaEnderecoRecord
  extends Record<IPessoaFisicaEndereco>,
    IPessoaFisicaEndereco {}

export class PessoaFisicaEnderecoRecord extends Record(defaultValues) {}

export const fromJSON = (json) => {
  const id = json.id !== null ? String(json.id) : defaultValues.id;
  const numero = json.numero !== null ? json.numero : defaultValues.numero;
  const complemento =
    json.complemento !== null ? json.complemento : defaultValues.complemento;
  const cidade = json.cidade !== null ? json.cidade : defaultValues.cidade;
  const uf = json.uf !== null ? json.uf : defaultValues.uf;
  const logradouro =
    json.logradouro !== null ? json.logradouro : defaultValues.logradouro;

  return new PessoaFisicaEnderecoRecord({
    id,
    numero,
    complemento,
    cidade,
    uf,
    logradouro,
  });
};
