import { action as Action, createReducer } from 'typesafe-actions';
import { is } from 'immutable';
import { PessoaFisicaCoreActions, PessoaFisicaTypes } from './types';
import {
  INITIAL_STATE,
  IPessoaFisicaState,
  IPessoaFisicaByPessoaFisicaID,
  IPessoaFisicaRecord,
} from './models/pessoa-fisica';
import { normalize } from '../../../class/normalization';

export const pessoaFisicaCoreCreators = {
  clear: (): PessoaFisicaCoreActions => Action(PessoaFisicaTypes.CLEAR),
  add: (pessoaFisica: IPessoaFisicaRecord): PessoaFisicaCoreActions =>
    Action(PessoaFisicaTypes.ADD, { pessoaFisica }),
  remove: (pessoaFisicaID: string): PessoaFisicaCoreActions =>
    Action(PessoaFisicaTypes.REMOVE, { pessoaFisicaID }),
  update: (pessoaFisica: IPessoaFisicaRecord[]): PessoaFisicaCoreActions =>
    Action(PessoaFisicaTypes.UPDATE, { pessoaFisica }),
};

const pessoaFisicaCoreReducer = createReducer<
  IPessoaFisicaState,
  PessoaFisicaCoreActions
>(INITIAL_STATE)
  .handleType(PessoaFisicaTypes.CLEAR, () => INITIAL_STATE)
  .handleType(PessoaFisicaTypes.ADD, (state, action) => ({
    ...state,
    pessoaFisicaByPessoaFisicaID: state.pessoaFisicaByPessoaFisicaID.set(
      String(action.payload.pessoaFisica.id),
      action.payload.pessoaFisica,
    ),
  }))
  .handleType(PessoaFisicaTypes.UPDATE, (state, action) => {
    const normalized: IPessoaFisicaByPessoaFisicaID = normalize(
      action.payload.pessoaFisica,
    );
    return is(normalized, state.pessoaFisicaByPessoaFisicaID)
      ? { ...state }
      : {
          ...state,
          pessoaFisicaByPessoaFisicaID: state.pessoaFisicaByPessoaFisicaID.merge(
            normalized,
          ),
        };
  })
  .handleType(PessoaFisicaTypes.REMOVE, (state, action) => ({
    ...state,
    pessoaFisicaByPessoaFisicaID: state.pessoaFisicaByPessoaFisicaID.remove(
      action.payload.pessoaFisicaID,
    ),
  }));

export default pessoaFisicaCoreReducer;
