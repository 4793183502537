import Immutable, { Map, Record } from 'immutable';

import {
  IPessoaFisicaTelefoneRecord,
  PessoaFisicaTelefone,
} from './pessoa-fisica-telefone';
import {
  IPessoaFisicaEnderecoRecord,
  PessoaFisicaEnderecoRecord,
  fromJSON as fromJSONEndereco,
} from './pessoa-fisica-endereco';
import {
  IPessoaFisicaEmailRecord,
  PessoaFisicaEmailRecord,
} from './pessoa-fisica-email';
import {
  IPessoaFisicaContaRecord,
  PessoaFisicaContaRecord,
  fromJSON as fromJSONConta,
} from './pessoa-fisica-conta';

export interface IPessoaFisica {
  readonly id: string;
  readonly nome: string;
  readonly cpf: string;
  readonly rg: string;
  readonly orgao_emissor: string;
  readonly sexo: 'M' | 'F';
  readonly data_nasc: Date;
  readonly cliente: boolean;
  readonly funcionario: boolean;
  readonly fornecedor: boolean;
  readonly conta: IPessoaFisicaContaRecord;
  readonly email: IPessoaFisicaEmailRecord;
  readonly endereco: IPessoaFisicaEnderecoRecord;
  readonly telefone: IPessoaFisicaTelefoneRecord;
  readonly role: string;
}

const defaultValues: IPessoaFisica = {
  id: '',
  nome: '',
  cpf: '',
  rg: '',
  orgao_emissor: '',
  sexo: 'M',
  data_nasc: new Date(),
  cliente: false,
  funcionario: true,
  fornecedor: false,
  conta: new PessoaFisicaContaRecord(),
  email: new PessoaFisicaEmailRecord(),
  endereco: new PessoaFisicaEnderecoRecord(),
  telefone: new PessoaFisicaTelefone(),
  role: '',
};

export interface IPessoaFisicaRecord
  extends Record<IPessoaFisica>,
    IPessoaFisica {}

export class PessoaFisica extends Record(defaultValues) {}

export type IPessoaFisicaByPessoaFisicaID = Immutable.Map<
  string,
  IPessoaFisicaRecord
>;

export type IPessoaFisicaState = {
  pessoaFisicaByPessoaFisicaID: IPessoaFisicaByPessoaFisicaID;
};

export const INITIAL_STATE: IPessoaFisicaState = {
  pessoaFisicaByPessoaFisicaID: Map({}),
};

export const fromJSON = (json) => {
  const id = json.id !== null ? String(json.id) : defaultValues.id;
  const nome = json.nome !== null ? json.nome : defaultValues.nome;
  const cpf = json.cpf !== null ? json.cpf : defaultValues.cpf;
  const rg = json.rg !== null ? json.rg : defaultValues.rg;
  const orgao_emissor =
    json.orgao_emissor !== null
      ? json.orgao_emissor
      : defaultValues.orgao_emissor;
  const sexo = json.sexo !== null ? json.sexo : defaultValues.sexo;
  const data_nasc =
    json.data_nasc !== null ? json.data_nasc : defaultValues.data_nasc;
  const tipo_cliente =
    json.tipo_cliente !== null ? json.tipo_cliente : defaultValues.cliente;
  const tipo_funcionario =
    json.tipo_funcionario !== null
      ? json.tipo_funcionario
      : defaultValues.funcionario;
  const tipo_fornecedor =
    json.tipo_fornecedor !== null
      ? json.tipo_fornecedor
      : defaultValues.fornecedor;
  const conta =
    json.conta !== null ? fromJSONConta(json.conta) : defaultValues.conta;
  const endereco =
    json.endereco !== null
      ? fromJSONEndereco(json.endereco)
      : defaultValues.endereco;

  const telefone =
    json.telefone !== null ? json.telefone : defaultValues.telefone;
  const email = json.email !== null ? json.email : defaultValues.email;
  const role =
    json.roles !== null ? json.roles[0]?.id?.toString() : defaultValues.role;

  return new PessoaFisica({
    id,
    nome,
    cpf,
    rg,
    orgao_emissor,
    sexo,
    data_nasc,
    cliente: tipo_cliente,
    funcionario: tipo_funcionario,
    fornecedor: tipo_fornecedor,
    conta,
    endereco,
    telefone,
    email,
    role,
  });
};
