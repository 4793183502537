import React, { useMemo } from 'react';

import Icon from '@components/icons';
import { MenuWrapper } from '@components/dashboard/menu';

import navItems from '../../../../../nav';

import MenuItemWired from './menu-item-wired';
import MenuWired from './menu-wired';
import AvatarSectionWired from './avatar-section-wired';

const Menu: React.FC = () => {
  const renderedMenu = useMemo(
    () =>
      navItems.items.map((item) => {
        if ('children' in item) {
          return (
            <MenuWired
              key={item.title}
              title={item.title}
              icon={item.icon}
              list={item.children}
              checkRoute={item.checkRoute}
            />
          );
        }
        return (
          <MenuItemWired
            key={item.route}
            label={item.label}
            icon={<Icon icon={item.icon} />}
            route={item.route}
          />
        );
      }),
    [],
  );

  return (
    <MenuWrapper slotAvatarSection={<AvatarSectionWired />}>
      {renderedMenu}
    </MenuWrapper>
  );
};

export default React.memo(Menu);
