import { action as Action, createReducer } from 'typesafe-actions';

import {
  ForgotPasswordActions,
  ForgotPasswordTypes,
  IPayloadRequestAsyncAction,
} from './types';
import { INITIAL_STATE, IForgotPasswordState } from './model';

export const forgotPasswordCreators = {
  requestAsync: ({
    userEmail,
  }: IPayloadRequestAsyncAction): ForgotPasswordActions =>
    Action(ForgotPasswordTypes.REQUEST_ASYNC, {
      userEmail,
    }),
  requestSuccess: (): ForgotPasswordActions =>
    Action(ForgotPasswordTypes.REQUEST_SUCCESS),
  requestFail: (): ForgotPasswordActions =>
    Action(ForgotPasswordTypes.REQUEST_FAIL),
};

const forgotPasswordReducer = createReducer<
  IForgotPasswordState,
  ForgotPasswordActions
>(INITIAL_STATE)
  .handleType(ForgotPasswordTypes.REQUEST_ASYNC, (state) => ({
    ...state,
    loading: true,
  }))
  .handleType(ForgotPasswordTypes.REQUEST_SUCCESS, (state) => ({
    ...state,
    loading: false,
  }))
  .handleType(ForgotPasswordTypes.REQUEST_FAIL, (state) => ({
    ...state,
    loading: false,
  }));

export default forgotPasswordReducer;
