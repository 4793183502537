import { takeLatest, call, put } from 'redux-saga/effects';
import api from '@api';
import { toast } from 'react-toastify';
import { RequestAsyncAction, UpdatePasswordTypes } from './types';
import { updatePasswordCreators } from './duck';

function* createPassword({ payload }: RequestAsyncAction) {
  try {
    const { oldPassword, password, userID } = payload;
    yield call(api.put, `/user-password/${userID}`, { oldPassword, password });
    yield put(updatePasswordCreators.requestSuccess());
    toast.success('Senha alterada com sucesso!');
  } catch (err) {
    yield put(updatePasswordCreators.requestFail());
    toast.error('Não foi possível alterar senha');
  }
}

export default [takeLatest(UpdatePasswordTypes.REQUEST_ASYNC, createPassword)];
