import { createStore, compose, applyMiddleware } from 'redux';

export default (reducers: any, middlewares: any): any => {
  let enhancer = compose;
  if (process.env.NODE_ENV === 'development') {
    enhancer =
      (window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ &&
        window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
          trace: true,
          traceLimit: 5,
        })) ||
      compose;
  }
  return createStore(reducers, enhancer(applyMiddleware(...middlewares)));
};
