import React from 'react';
import ReactFC from "react-fusioncharts";
import ReactDOM from 'react-dom';
import AppSetup from './app.setup';

import FusionCharts from "fusioncharts";

ReactFC.fcRoot(FusionCharts);

ReactDOM.render(
  <React.StrictMode>
    <AppSetup />
  </React.StrictMode>,
  document.getElementById('root'),
);
