import { ApplicationState } from '..';

import { createSelector } from '../../class/memoize/createSelector';

const getState = (globalState: ApplicationState) => globalState.auth;

const getIsLoading = createSelector([getState], (state) => state.loading);

const getIsSigned = createSelector([getState], (state) => state.signed);

export default Object.freeze({
  getIsLoading,
  getIsSigned,
});
