export type IRequestStatus = 'pristine' | 'progress' | 'success' | 'failure';

export interface IPessoaJuridicaFormState {
  selectedPessoaJuridicaID: string;
  createFinalizationStatus: IRequestStatus;
  isLoading: boolean;
}

export const INITIAL_STATE: IPessoaJuridicaFormState = {
  selectedPessoaJuridicaID: '',
  createFinalizationStatus: 'pristine',
  isLoading: false,
};
