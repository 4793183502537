import { combineReducers } from 'redux';

import {
  createPasswordCreators,
  createPasswordReducer,
  createPasswordSagas,
  createPasswordSelectors,
} from './create-password';

import {
  updatePasswordCreators,
  updatePasswordReducer,
  updatePasswordSagas,
  updatePasswordSelectors,
} from './update-password';

import {
  forgotPasswordCreators,
  forgotPasswordReducer,
  forgotPasswordSagas,
  forgotPasswordSelectors,
} from './forgot-password';

export const passwordCreators = Object.freeze({
  create: createPasswordCreators,
  update: updatePasswordCreators,
  forgot: forgotPasswordCreators,
});

export const passwordSelectors = Object.freeze({
  create: createPasswordSelectors,
  update: updatePasswordSelectors,
  forgot: forgotPasswordSelectors,
});

export const passwordSagas = [
  ...createPasswordSagas,
  ...updatePasswordSagas,
  ...forgotPasswordSagas,
];

export default combineReducers({
  create: createPasswordReducer,
  update: updatePasswordReducer,
  forgot: forgotPasswordReducer,
});
