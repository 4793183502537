import React from 'react';
import { ThemeProvider, createMuiTheme } from '@material-ui/core/styles';

import { Button } from '@material-ui/core';
import blue from '@material-ui/core/colors/blue';

interface ButtonProps {
  label: string;
  type?: 'button' | 'reset' | 'submit';
  onClick?: (e: React.MouseEvent) => void;
  variant?: 'text' | 'outlined' | 'contained';
  color?: 'primary' | 'inherit' | 'secondary';
  className?: string;
  width?: string;
  height?: string;
  disabled?: boolean;
  fullWidth?: boolean;
  style?: any;
}

const theme = createMuiTheme({
  palette: {
    primary: blue,
  },
});

const ButtonMaterial: React.FC<ButtonProps> = ({
  label,
  onClick,
  type = 'button',
  color,
  disabled,
  variant,
  fullWidth,
  className,
  style,
}) => {
  return (
    <ThemeProvider theme={theme}>
      <Button
        disabled={disabled}
        onClick={onClick}
        type={type}
        color={color}
        variant={variant}
        fullWidth={fullWidth}
        className={className}
        style={style}
      >
        {label}
      </Button>
    </ThemeProvider>
  );
};

export default ButtonMaterial;
