import { action as Action, createReducer } from 'typesafe-actions';

import { CreatePasswordActions, CreatePasswordTypes } from './types';
import { INITIAL_STATE, ICreatePasswordState } from './model';

export const createPasswordCreators = {
  requestAsync: (password: string, token: string): CreatePasswordActions =>
    Action(CreatePasswordTypes.REQUEST_ASYNC, { password, token }),
  requestSuccess: (): CreatePasswordActions =>
    Action(CreatePasswordTypes.REQUEST_SUCCESS),
  requestFail: (): CreatePasswordActions =>
    Action(CreatePasswordTypes.REQUEST_FAIL),
};

const createPasswordReducer = createReducer<
  ICreatePasswordState,
  CreatePasswordActions
>(INITIAL_STATE)
  .handleType(CreatePasswordTypes.REQUEST_ASYNC, (state) => ({
    ...state,
    loading: true,
  }))
  .handleType(CreatePasswordTypes.REQUEST_SUCCESS, (state) => ({
    ...state,
    loading: false,
  }))
  .handleType(CreatePasswordTypes.REQUEST_FAIL, (state) => ({
    ...state,
    loading: false,
  }));

export default createPasswordReducer;
