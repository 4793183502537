import { Record } from 'immutable';

export interface ILogradouro {
  readonly id: string;
  readonly id_cidade: number;
  readonly cep: string;
  readonly bairro: string;
  readonly logradouro: string;
}

const defaultValues: ILogradouro = {
  id: '',
  logradouro: '',
  bairro: '',
  cep: '',
  id_cidade: 0,
};

export interface ILogradouroRecord extends Record<ILogradouro>, ILogradouro {}

export class LogradouroRecord extends Record(defaultValues) {}

export const fromJSON = (json) => {
  const id = json.id !== null ? String(json.id) : defaultValues.id;
  const logradouro =
    json.logradouro !== null ? json.logradouro : defaultValues.logradouro;
  const bairro = json.bairro !== null ? json.bairro : defaultValues.bairro;
  const cep = json.cep !== null ? json.cep : defaultValues.cep;
  const id_cidade =
    json.id_cidade !== null ? json.id_cidade : defaultValues.id_cidade;

  return new LogradouroRecord({
    id,
    logradouro,
    bairro,
    cep,
    id_cidade,
  });
};
