/* eslint-disable no-use-before-define */
import { call, takeLatest, put } from 'redux-saga/effects';
import { toast } from 'react-toastify';
import { format } from 'date-fns';
import { List } from 'immutable';

import api from '@api';

import {
  PessoaFisicaFormDTO,
  SavePessoaFisicaAsyncAction,
  PessoaFisicaFormTypes,
  UpdateRequestAsyncAction,
  TipoCadastroType,
  RemoveRequestAsyncAction,
} from './types';
import { pessoaFisicaFormCreators } from './duck';

import cleanEmptyValues from '../../../../utils/filterObject';
import { pessoaFisicaCoreCreators } from '../../core/pessoa-fisica/duck';
import { fromJSON } from '../../core/pessoa-fisica/models/pessoa-fisica';

function showError(response: any, action: string) {
  if (response?.data?.statusCode === 400) {
    toast.error(response.data.message[0]);
    return;
  }
  toast.error(`Falha ao ${action} Pessoa Física`);
}

function* watchSavePessoaFisica({ payload }: SavePessoaFisicaAsyncAction) {
  const normalizedData = sanitizeData(payload.data);
  const data = cleanEmptyValues(normalizedData);
  const endereco = cleanEmptyValues(normalizedData.endereco);
  const conta = cleanEmptyValues(normalizedData.conta);
  try {
    const response = yield call(api.post, '/gcpf', {
      ...data,
      endereco,
      conta,
    });
    const gcpf = fromJSON(response.data);
    yield put(pessoaFisicaCoreCreators.add(gcpf));
    yield put(pessoaFisicaFormCreators.setPessoaFisicaID(gcpf.id));
    yield put(pessoaFisicaFormCreators.savePessoaFisicaSuccess());
    toast.success('Pessoa Física cadastrada com sucesso!');
  } catch (er) {
    yield put(pessoaFisicaFormCreators.requestFinished('failure'));
    const { response }: any = er;
    showError(response, 'cadastrar');
  }
}

function* watchUpdatePessoaFisica({ payload }: UpdateRequestAsyncAction) {
  const normalizedData = sanitizeData(payload.data);
  const data = cleanEmptyValues(normalizedData);
  const endereco = cleanEmptyValues(normalizedData.endereco);
  const conta = cleanEmptyValues(normalizedData.conta);
  const { pessoaFisicaID } = payload;
  try {
    const response = yield call(api.put, `/gcpf/${pessoaFisicaID}`, {
      ...data,
      endereco,
      conta,
    });
    const gcpf = fromJSON(response.data);
    yield put(pessoaFisicaCoreCreators.add(gcpf));
    yield put(pessoaFisicaFormCreators.requestFinished('success'));
    toast.success('Cadastro atualizado!');
  } catch (er) {
    yield put(pessoaFisicaFormCreators.requestFinished('failure'));
    const { response }: any = er;
    showError(response, 'atualizar');
  }
}

function* watchRemovePessoaFisica({ payload }: RemoveRequestAsyncAction) {
  const { pessoaFisicaID } = payload;
  try {
    yield call(api.delete, `/gcpf/${pessoaFisicaID}`);
    yield put(pessoaFisicaCoreCreators.remove(pessoaFisicaID));
    yield put(pessoaFisicaFormCreators.requestFinished('success'));
    toast.success('Pessoa Física removida com sucesso!');
  } catch (er) {
    yield put(pessoaFisicaFormCreators.requestFinished('failure'));
    const { response }: any = er;
    showError(response, 'remover');
  }
}

export default [
  takeLatest(
    PessoaFisicaFormTypes.SAVE_PESSOA_FISICA_ASYNC,
    watchSavePessoaFisica,
  ),
  takeLatest(
    PessoaFisicaFormTypes.UPDATE_PESSOA_FISICA_ASYNC,
    watchUpdatePessoaFisica,
  ),
  takeLatest(
    PessoaFisicaFormTypes.REMOVE_PESSOA_FISICA_ASYNC,
    watchRemovePessoaFisica,
  ),
];

function sanitizeData(data: Partial<PessoaFisicaFormDTO>) {
  const tipo_cadastro = List([
    ...(data.tipo_cadastro as Array<TipoCadastroType>),
  ]);
  return {
    nome: data.nome,
    data_nasc: format(data.data_nasc!, 'yyyy/MM/dd HH:mm:ss'),
    cpf: data.cpf,
    rg: data.rg,
    sexo: data.sexo,
    orgao_emissor: data.orgao_emissor,
    cliente: tipo_cadastro?.some((tipo) => tipo.value === 'tipo_cliente'),
    funcionario: tipo_cadastro?.some(
      (tipo) => tipo.value === 'tipo_funcionario',
    ),
    fornecedor: false,
    email: {
      email: data.email,
    },
    endereco: {
      id_logradouro: Number(data.id_logradouro),
      id_uf: Number(data.id_uf),
      id_cidade: Number(data.id_cidade),
      numero: data.numero,
      complemento: data.complemento,
    },
    conta: {
      id_banco: Number(data.id_banco?.value),
      agencia: data.agencia?.toString(),
      agencia_dv: data.agencia_dv?.toString(),
      conta: data.conta?.toString(),
      conta_dv: data.conta_dv?.toString(),
      operacao: data.operacao?.toString(),
    },
    telefone: {
      ddd: data.ddd?.toString(),
      telefone: data.telefone?.toString(),
    },
    role: data.role,
  };
}
