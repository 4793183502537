import React, { useCallback, useContext } from 'react';

import { ButtonHamburger } from '../../../../components/buttons';
import { TemplateContext } from '../context';

import { Container, Content, RightContainer, LeftContainer } from './styles';

const Header = () => {
  const { state, dispatch } = useContext(TemplateContext);

  const handleClick = useCallback(() => {
    if (!state.showAside) {
      dispatch({ type: 'SHOW_ASIDE' });
      return;
    }
    dispatch({ type: 'HIDE_ASIDE' });
  }, [state.showAside, dispatch]);

  return (
    <Container isOpen={state.showAside}>
      <Content>
        <LeftContainer>
          <ButtonHamburger onClick={handleClick} />
        </LeftContainer>
        <RightContainer />
      </Content>
    </Container>
  );
};

export default Header;
