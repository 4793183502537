import { takeLatest, call, put } from 'redux-saga/effects';
import api from '@api';
import { toast } from 'react-toastify';
import { RequestAsyncAction, ForgotPasswordTypes } from './types';
import { forgotPasswordCreators } from './duck';

function* createPassword({ payload }: RequestAsyncAction) {
  try {
    const { userEmail } = payload;
    yield call(api.post, '/user-password/forgot-password', { userEmail });
    yield put(forgotPasswordCreators.requestSuccess());
    toast.success(
      'Acesse seu e-mail e siga as instruções para alterar sua senha.',
    );
  } catch (err) {
    yield put(forgotPasswordCreators.requestFail());
    toast.error('Email não encontrado');
  }
}

export default [takeLatest(ForgotPasswordTypes.REQUEST_ASYNC, createPassword)];
