import { createReducer } from 'typesafe-actions';
import { IUserInitialState, INITIAL_STATE } from './model';
import { UserActions } from './types';
import { AuthTypes } from '../auth/types';

const userReducer = createReducer<IUserInitialState, UserActions>(
  INITIAL_STATE,
).handleType(AuthTypes.SIGN_IN_SUCCESS, (state, action) => ({
  ...state,
  userEmail: action.payload.userEmail,
  userID: action.payload.userID,
  role: action.payload.role,
  userName: action.payload.userName,
}));

export default userReducer;
