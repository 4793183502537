export enum PessoaFisicaBaseTypes {
  CLEAR = '@PESSOA_FISICA_BASE/CLEAR',
  FETCH_ASYNC = '@PESSOA_FISICA_BASE/FETCH_ASYNC',
  FETCH_SUCCESS = '@PESSOA_FISICA_BASE/FETCH_SUCCESS',
  SET_FETCHED_PESSOA_FISICA_ID = '@PESSOA_FISICA_BASE/SET_FETCHED_PESSOA_FISICA_ID',
  FETCH_FAIL = '@PESSOA_FISICA_BASE/FETCH_FAIL',
}

// Action types

interface ClearAction {
  type: typeof PessoaFisicaBaseTypes.CLEAR;
}

export interface FetchAsyncAction {
  type: typeof PessoaFisicaBaseTypes.FETCH_ASYNC;
  payload: {
    page?: number;
  };
}

interface FetchSuccessAction {
  type: typeof PessoaFisicaBaseTypes.FETCH_SUCCESS;
  payload: {
    page: number;
    totalFetchedPessoaFisica: number;
  };
}

interface FetchFailAction {
  type: typeof PessoaFisicaBaseTypes.FETCH_FAIL;
}

interface SetFetchedPessoaFisicaIDsAction {
  type: typeof PessoaFisicaBaseTypes.SET_FETCHED_PESSOA_FISICA_ID;
  payload: {
    pessoaFisicaIDs: ReadonlyArray<string>;
  };
}

export type PessoaFisicaBaseActions =
  | ClearAction
  | FetchAsyncAction
  | FetchSuccessAction
  | SetFetchedPessoaFisicaIDsAction
  | FetchFailAction;
