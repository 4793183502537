/* eslint-disable react/jsx-one-expression-per-line */
import React, { useEffect, useState } from 'react';
import TemplateScreen from '@components/template-screen';
import { Typography, Card, CardContent } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import { useParams } from 'react-router-dom';
import api from '@api';
import { cpfMask } from '@utils/cpf-mask';
import { formatDateUsToBr } from '@utils/format-date-us-to-br';
import { toMoneyString } from '@utils/to-money-string';
import { toast } from 'react-toastify';

const ClientesPfDetails: React.FC = () => {
  const urlParams: any = useParams();
  const [gcpf, setGcpf] = useState<any>();

  useEffect(() => {
    const getGcpfs = async () => {
      try {
        await api.get(`/gcpf/loadById?id=${urlParams.id}`).then((response) => {
          setGcpf(response.data);
        });
      } catch (error) {
        toast.error('Erro ao carregar dados.');
      }
    };
    getGcpfs();
  }, [urlParams]);

  return (
    <TemplateScreen title="Clientes PF" onBack>
      {gcpf && (
        <>
          <Card style={{ margin: '20px' }}>
            <CardContent style={{ color: '#494f54' }}>
              <Typography style={{ fontWeight: 'bold' }} component="div">
                Dados pessoais
              </Typography>
              <Grid container style={{ padding: '10px' }}>
                <Grid item xs={4}>
                  <Typography variant="body1" style={{ fontWeight: 'bold' }}>
                    Nome
                  </Typography>
                  <Typography variant="body2">{gcpf.nome}</Typography>
                </Grid>
                <Grid item xs={2}>
                  <Typography variant="body1" style={{ fontWeight: 'bold' }}>
                    CPF
                  </Typography>
                  <Typography variant="body1">{cpfMask(gcpf.cpf)}</Typography>
                </Grid>
                <Grid item xs={2}>
                  <Typography variant="body1" style={{ fontWeight: 'bold' }}>
                    Registro Geral
                  </Typography>
                  <Typography variant="body1">{gcpf.rg}</Typography>
                </Grid>
                <Grid item xs={2}>
                  <Typography variant="body1" style={{ fontWeight: 'bold' }}>
                    Orgão Emissor
                  </Typography>
                  <Typography variant="body1">{gcpf.orgao_emissor}</Typography>
                </Grid>
                <Grid item xs={2}>
                  <Typography variant="body1" style={{ fontWeight: 'bold' }}>
                    Data Nascimento
                  </Typography>
                  <Typography variant="body1">
                    {formatDateUsToBr(gcpf.data_nasc)}
                  </Typography>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
          <Card style={{ margin: '20px' }}>
            <CardContent style={{ color: '#494f54' }}>
              <Typography style={{ fontWeight: 'bold' }} component="div">
                Telefones
              </Typography>
              <Grid container style={{ padding: '10px' }}>
                {gcpf.telefones.length > 0 ? (
                  gcpf.telefones.map((telefone) => (
                    <Grid item xs={3} key={telefone.id}>
                      <Typography variant="body1">
                        ({telefone.ddd}){telefone.telefone}
                      </Typography>
                    </Grid>
                  ))
                ) : (
                  <Typography variant="body1">Sem informações</Typography>
                )}
              </Grid>
            </CardContent>
          </Card>
          <Card style={{ margin: '20px' }}>
            <CardContent style={{ color: '#494f54' }}>
              <Typography style={{ fontWeight: 'bold' }} component="div">
                Dados de endereços
              </Typography>
              {gcpf.enderecos.length > 0 ? (
                gcpf.enderecos.map((endereco) => (
                  <Grid container style={{ padding: '10px' }} key={endereco.id}>
                    <Grid item xs={2}>
                      <Typography
                        variant="body1"
                        style={{ fontWeight: 'bold' }}
                      >
                        Cidade
                      </Typography>
                      <Typography variant="body1">
                        {endereco.cidade.nome}
                      </Typography>
                    </Grid>

                    <Grid item xs={1}>
                      <Typography
                        variant="body1"
                        style={{ fontWeight: 'bold' }}
                      >
                        UF
                      </Typography>
                      <Typography variant="body1">
                        {endereco.uf.nome}
                      </Typography>
                    </Grid>
                    <Grid item xs={1}>
                      <Typography
                        variant="body1"
                        style={{ fontWeight: 'bold' }}
                      >
                        Cep
                      </Typography>
                      <Typography variant="body1">
                        {endereco.logradouro.cep}
                      </Typography>
                    </Grid>

                    <Grid item xs={3}>
                      <Typography
                        variant="body1"
                        style={{ fontWeight: 'bold' }}
                      >
                        Logradouro
                      </Typography>
                      <Typography variant="body1">
                        {endereco.logradouro.logradouro}
                      </Typography>
                    </Grid>

                    <Grid item xs={1}>
                      <Typography
                        variant="body1"
                        style={{ fontWeight: 'bold' }}
                      >
                        Número
                      </Typography>
                      <Typography variant="body1">{endereco.numero}</Typography>
                    </Grid>

                    <Grid item xs={2}>
                      <Typography
                        variant="body1"
                        style={{ fontWeight: 'bold' }}
                      >
                        Bairro
                      </Typography>
                      <Typography variant="body1">
                        {endereco.logradouro.bairro}
                      </Typography>
                    </Grid>

                    <Grid item xs={2}>
                      <Typography
                        variant="body1"
                        style={{ fontWeight: 'bold' }}
                      >
                        Complemento
                      </Typography>
                      <Typography variant="body1">
                        {endereco.complemento || 'NÃO INFORMADO'}
                      </Typography>
                    </Grid>
                  </Grid>
                ))
              ) : (
                <Typography variant="body1">Sem informações</Typography>
              )}
            </CardContent>
          </Card>
          <Card style={{ margin: '20px' }}>
            <CardContent style={{ color: '#494f54' }}>
              <Typography style={{ fontWeight: 'bold' }} component="div">
                Dados bancários
              </Typography>
              {gcpf.conta ? (
                <Grid container style={{ padding: '10px' }}>
                  <Grid item xs={4}>
                    <Typography variant="body1" style={{ fontWeight: 'bold' }}>
                      Banco
                    </Typography>
                    <Typography variant="body2">
                      {gcpf.conta.banco.nome_banco}
                    </Typography>
                  </Grid>
                  <Grid item xs={2}>
                    <Typography variant="body1" style={{ fontWeight: 'bold' }}>
                      Conta
                    </Typography>
                    <Typography variant="body1">{gcpf.conta.conta}</Typography>
                  </Grid>
                  <Grid item xs={2}>
                    <Typography variant="body1" style={{ fontWeight: 'bold' }}>
                      Agência
                    </Typography>
                    <Typography variant="body1">
                      {gcpf.conta.agencia}
                    </Typography>
                  </Grid>
                  <Grid item xs={2}>
                    <Typography variant="body1" style={{ fontWeight: 'bold' }}>
                      Margem
                    </Typography>
                    <Typography variant="body1">
                      {gcpf?.gcpf_cliente_margem
                        ? toMoneyString(gcpf.gcpf_cliente_margem.margem)
                        : toMoneyString(0)}
                    </Typography>
                  </Grid>
                </Grid>
              ) : (
                <Typography variant="body1">Sem informações</Typography>
              )}
            </CardContent>
          </Card>
          <Card style={{ margin: '20px' }}>
            <CardContent style={{ color: '#494f54' }}>
              <Typography style={{ fontWeight: 'bold' }} component="div">
                Propostas vinculadas
              </Typography>
              {gcpf.cdc.length > 0 && (
                <Grid container style={{ marginTop: '10px' }}>
                  <Grid item xs={2}>
                    <Typography style={{ fontWeight: 'bold' }} component="div">
                      Proposta
                    </Typography>
                  </Grid>
                  <Grid item xs={2}>
                    <Typography style={{ fontWeight: 'bold' }} component="div">
                      Parcelas
                    </Typography>
                  </Grid>
                  <Grid item xs={2}>
                    <Typography style={{ fontWeight: 'bold' }} component="div">
                      Conformidade
                    </Typography>
                  </Grid>
                </Grid>
              )}
              {gcpf.cdc.length > 0 ? (
                gcpf.cdc.map((cdc) => (
                  <Grid container key={cdc.id}>
                    <Grid item xs={2}>
                      <Typography component="div">{cdc.proposta}</Typography>
                    </Grid>
                    <Grid item xs={2}>
                      <Typography component="div">{cdc.num_parcela}</Typography>
                    </Grid>
                    <Grid item xs={2}>
                      <Typography component="div">
                        {cdc.produto_conformidade.nome}
                      </Typography>
                    </Grid>
                  </Grid>
                ))
              ) : (
                <Typography variant="body1">Sem informações</Typography>
              )}
            </CardContent>
          </Card>
          <Card style={{ margin: '20px' }}>
            <CardContent style={{ color: '#494f54' }}>
              <Typography style={{ fontWeight: 'bold' }} component="div">
                Benefícios
              </Typography>
              <Typography component="div">Sem informações</Typography>
            </CardContent>
          </Card>
          <Card style={{ margin: '20px' }}>
            <CardContent style={{ color: '#494f54' }}>
              <Typography style={{ fontWeight: 'bold' }} component="div">
                Contatos com o cliente
              </Typography>
              <Typography component="div">Sem informações</Typography>
            </CardContent>
          </Card>
          <Card style={{ margin: '20px' }}>
            <CardContent style={{ color: '#494f54' }}>
              <Typography style={{ fontWeight: 'bold' }} component="div">
                Mailings vinculados
              </Typography>
              <Typography component="div">Sem informações</Typography>
            </CardContent>
          </Card>
          <Card style={{ margin: '20px' }}>
            <CardContent style={{ color: '#494f54' }}>
              <Typography style={{ fontWeight: 'bold' }} component="div">
                Gravações
              </Typography>
              <Typography component="div">Sem informações</Typography>
            </CardContent>
          </Card>
          <Card style={{ margin: '20px' }}>
            <CardContent style={{ color: '#494f54' }}>
              <Typography style={{ fontWeight: 'bold' }} component="div">
                Históricos
              </Typography>
              <Typography component="div">Sem informações</Typography>
            </CardContent>
          </Card>
        </>
      )}
    </TemplateScreen>
  );
};

export default ClientesPfDetails;
