export enum UpdatePasswordTypes {
  REQUEST_ASYNC = '@UPDATE_PASSWORD/REQUEST_ASYNC',
  REQUEST_SUCCESS = '@UPDATE_PASSWORD/REQUEST_SUCCESS',
  REQUEST_FAIL = '@UPDATE_PASSWORD/REQUEST_FAIL',
}

// Action Types

export type IPayloadRequestAsyncAction = {
  userID: string;
  oldPassword: string;
  password: string;
};

export interface RequestAsyncAction {
  type: typeof UpdatePasswordTypes.REQUEST_ASYNC;
  payload: IPayloadRequestAsyncAction;
}

interface RequestSuccessAction {
  type: typeof UpdatePasswordTypes.REQUEST_SUCCESS;
}

interface RequestFailAction {
  type: typeof UpdatePasswordTypes.REQUEST_FAIL;
}

export type UpdatePasswordActions =
  | RequestAsyncAction
  | RequestSuccessAction
  | RequestFailAction;
