/* eslint-disable no-use-before-define */
import { call, takeLatest, put } from 'redux-saga/effects';
import { toast } from 'react-toastify';
import { format, parseISO } from 'date-fns';

import api from '@api';

import {
  PessoaJuridicaFormDTO,
  SavePessoaJuridicaAsyncAction,
  PessoaJuridicaFormTypes,
  UpdateRequestAsyncAction,
  RemoveRequestAsyncAction,
} from './types';
import { pessoaJuridicaFormCreators } from './duck';

import cleanEmptyValues from '../../../../utils/filterObject';
import { pessoaJuridicaCoreCreators } from '../../core/pessoa-juridica/duck';
import { fromJSON } from '../../core/pessoa-juridica/models/pessoa-juridica';

function showError(response: any, action: string) {
  if (response?.data?.statusCode === 400) {
    toast.error(response.data.message[0]);
    return;
  }
  toast.error(`Falha ao ${action} Pessoa Física`);
}

function* watchSavePessoaFisica({ payload }: SavePessoaJuridicaAsyncAction) {
  const normalizedData = sanitizeData(payload.data);
  const data = cleanEmptyValues(normalizedData);
  const endereco = cleanEmptyValues(normalizedData.endereco);
  const conta = cleanEmptyValues(normalizedData.conta);
  try {
    const response = yield call(api.post, '/gcpj', {
      ...data,
      endereco,
      conta,
    });
    const gcpj = fromJSON(response.data);
    yield put(pessoaJuridicaCoreCreators.add(gcpj));
    yield put(pessoaJuridicaFormCreators.setPessoaJuridicaID(gcpj.id));
    yield put(pessoaJuridicaFormCreators.savePessoaJuridicaSuccess());
    toast.success('Pessoa Física cadastrada com sucesso!');
  } catch (er) {
    yield put(pessoaJuridicaFormCreators.requestFinished('failure'));
    console.log({ er });
    const { response } = er;
    showError(response, 'cadastrar');
  }
}

function* watchUpdatePessoaFisica({ payload }: UpdateRequestAsyncAction) {
  const normalizedData = sanitizeData(payload.data);
  const data = cleanEmptyValues(normalizedData);
  const endereco = cleanEmptyValues(normalizedData.endereco);
  const conta = cleanEmptyValues(normalizedData.conta);
  const { pessoaJuridicaID } = payload;
  try {
    const response = yield call(api.put, `/gcpj/${pessoaJuridicaID}`, {
      ...data,
      endereco,
      conta,
    });
    const gcpj = fromJSON(response.data);
    yield put(pessoaJuridicaCoreCreators.add(gcpj));
    yield put(pessoaJuridicaFormCreators.requestFinished('success'));
    toast.success('Pessoa física atualizada com sucesso!');
  } catch (er) {
    yield put(pessoaJuridicaFormCreators.requestFinished('failure'));
    const { response } = er;
    showError(response, 'atualizar');
  }
}

function* watchRemovePessoaJuridica({ payload }: RemoveRequestAsyncAction) {
  const { pessoaJuridicaID } = payload;
  try {
    yield call(api.delete, `/gcpj/${pessoaJuridicaID}`);
    yield put(pessoaJuridicaCoreCreators.remove(pessoaJuridicaID));
    yield put(pessoaJuridicaFormCreators.requestFinished('success'));
    toast.success('Pessoa Jurídica removida com sucesso!');
  } catch (er) {
    yield put(pessoaJuridicaFormCreators.requestFinished('failure'));
    const { response } = er;
    showError(response, 'remover');
  }
}

export default [
  takeLatest(
    PessoaJuridicaFormTypes.SAVE_PESSOA_JURIDICA_ASYNC,
    watchSavePessoaFisica,
  ),
  takeLatest(
    PessoaJuridicaFormTypes.UPDATE_PESSOA_JURIDICA_ASYNC,
    watchUpdatePessoaFisica,
  ),
  takeLatest(
    PessoaJuridicaFormTypes.REMOVE_PESSOA_JURIDICA_ASYNC,
    watchRemovePessoaJuridica,
  ),
];

function sanitizeData(data: Partial<PessoaJuridicaFormDTO>) {
  const dtabertura = data.dt_abertura as Date | string;
  const date =
    typeof dtabertura === 'object' ? dtabertura : parseISO(dtabertura);
  return {
    nome_fantasia: data.nome_fantasia,
    razao_social: data.razao_social,
    dt_abertura:
      typeof dtabertura === 'object'
        ? format(date, 'yyyy/MM/dd HH:mm:ss')
        : dtabertura,
    cnpj: data.cnpj,
    ie: data.ie,
    ie_isenta: data.ie_isenta,
    cliente: data.tipo_cadastro?.some((tipo) => tipo.value === 'cliente'),
    parceiro: data.tipo_cadastro?.some((tipo) => tipo.value === 'parceiro'),
    fornecedor: false,
    email: {
      email: data.email,
    },
    endereco: {
      id_logradouro: Number(data.id_logradouro),
      id_uf: Number(data.id_uf),
      id_cidade: Number(data.id_cidade),
      numero: data.numero,
      complemento: data.complemento,
    },
    conta: {
      id_banco: Number(data.id_banco?.value),
      agencia: data.agencia?.toString(),
      agencia_dv: data.agencia_dv?.toString(),
      conta: data.conta?.toString(),
      conta_dv: data.conta_dv?.toString(),
      operacao: data.operacao?.toString(),
    },
    telefone: {
      ddd: data.ddd?.toString(),
      telefone: data.telefone?.toString(),
    },
  };
}
