import { takeLatest, call, put } from 'redux-saga/effects';
import { toast } from 'react-toastify';

import { fetchPessoaJuridica } from '../../core/pessoa-juridica/sagas';

import { FetchAsyncAction, PessoaJuridicaBaseTypes } from './types';
import { pessoaJuridicaBaseCreators } from './duck';

function* watchPessoaJuridicaFetchAsync({ payload }: FetchAsyncAction) {
  try {
    const response = yield call(fetchPessoaJuridica, payload.page);

    yield put(
      pessoaJuridicaBaseCreators.fetchSuccess(response.page, response.total),
    );

    const pessoaJuridicaIDs = response.gcpj.map((gcpjRow) =>
      String(gcpjRow.id),
    );

    yield put(
      pessoaJuridicaBaseCreators.setFetchedPessoaJuridicaIDs(pessoaJuridicaIDs),
    );
  } catch (err) {
    console.log(err);
    toast.error('Falha ao carregar Pessoa Jurídica');
    yield put(pessoaJuridicaBaseCreators.fetchFail());
  }
}

export default [
  takeLatest(
    PessoaJuridicaBaseTypes.FETCH_ASYNC,
    watchPessoaJuridicaFetchAsync,
  ),
];
