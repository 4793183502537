import styled from 'styled-components';

interface IconContainerProps {
  isOpen: boolean;
  isActive?: boolean;
}

export const Container = styled.li`
  position: relative;
  display: flex;
  flex-direction: column;
  color: ${({ theme }) => theme.semantic.light};
  transition: background 0.2s ease;
  cursor: pointer;
`;

export const Content = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 13px;
  width: 100%;
`;

export const RightContainer = styled.div<{ isActive?: boolean }>`
  color: ${({ isActive, theme }) => (isActive ? '#2E6FB2' : '#718096')};
  font-weight: normal;
  display: flex;
  align-items: center;
  /* margin-left: 10px; */
`;

export const IconContainer = styled.div<IconContainerProps>`
  color: ${({ isActive, theme }) =>
    isActive ? theme.semantic.primary : '#718096'};
  transform: ${({ isOpen }) => {
    if (isOpen) {
      return 'rotate(180deg)';
    }
    return 'rotate(90deg)';
  }};
  transition: transform 0.1s ease;
`;

export const Text = styled.span`
  padding: 0 5px;
`;

export const List = styled.ul<IconContainerProps>`
  display: flex;
  flex-direction: column;
  overflow: hidden;
  height: fit-content;
  max-height: 400px;
  ${({ isOpen }) => !isOpen && 'max-height: 0'};
  transition: all 0.2s linear;
  background-color: ${({ theme }) => theme.semantic.light};
  margin-left: 5px !important;
`;

export const Pipe = styled.div<{ isActive: boolean }>`
  display: ${({ isActive }) => (isActive ? 'block' : 'none')};
  height: 41px;
  width: 3px;
  background-color: ${({ theme }) => theme.semantic.light};
  position: absolute;
  left: 0;
`;
