import { hot } from 'react-hot-loader/root';
import React from 'react';
import { Provider } from 'react-redux';
import { ToastContainer } from 'react-toastify';
import { PersistGate } from 'redux-persist/integration/react';
import { ThemeProvider } from 'styled-components';
import { setConfig } from 'react-hot-loader';

import {
  createMuiTheme,
  ThemeProvider as MaterialThemeProvider,
} from '@material-ui/core/styles';
import { ptBR } from '@material-ui/core/locale';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import blue from '@material-ui/core/colors/blue';

import DateFnsUtils from '@date-io/date-fns';
import ptBr from 'date-fns/locale/pt-BR';

import 'react-toastify/dist/ReactToastify.css';
import 'react-datepicker/dist/react-datepicker.css';

import CreateGlobalStyle from './styles/global';
import { store, persistor } from './store';
import theme from './styles/theme';
import App from './App';
import { TemplateProvider } from './screens/template/dashboard/context';

setConfig({
  reloadHooks: false,
});

const materialTheme = createMuiTheme(
  {
    palette: {
      primary: blue,
    },
    typography: {
      fontFamily: 'Poppins, sans-serif',
    },
  },
  ptBR,
);

const AppSetup: React.FC = () => {
  return (
    <Provider store={store}>
      <PersistGate persistor={persistor}>
        <ToastContainer />
        <TemplateProvider>
          <CreateGlobalStyle />
          <ThemeProvider theme={theme}>
            <MaterialThemeProvider theme={materialTheme}>
              <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ptBr}>
                <App />
              </MuiPickersUtilsProvider>
            </MaterialThemeProvider>
          </ThemeProvider>
        </TemplateProvider>
      </PersistGate>
    </Provider>
  );
};

export default hot(AppSetup);
