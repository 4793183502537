import React from 'react';
import { Map } from 'immutable';

import { useTheme } from '@hooks';
import { Container, Loader } from './styles';

interface ButtonLoaderProps {
  isLoading: boolean;
  label: string;
  type?: string;
  bgColor?: 'primary' | 'success' | 'info' | 'warning' | 'danger';
  onClick?: (e: React.MouseEvent | any) => void;
  width?: string;
  height?: string;
}

const ButtonLoader: React.FC<ButtonLoaderProps> = ({
  isLoading,
  label,
  type = 'button',
  bgColor,
  onClick,
  width,
  height,
}) => {
  const theme = useTheme();
  const BackgroundColorMap = Map({
    primary: theme.semantic.primary,
    danger: theme.semantic.danger,
    success: theme.semantic.success,
    info: theme.semantic.info,
    warning: theme.semantic.warning,
  });
  const backgroundColor = bgColor && BackgroundColorMap.get(bgColor, false);

  return (
    <Container
      type={type}
      bgColor={backgroundColor}
      isLoading={isLoading}
      onClick={onClick}
      width={width}
      height={height}
    >
      {isLoading ? <Loader /> : label}
    </Container>
  );
};

export default ButtonLoader;
