import React from 'react';

import { FaPowerOff } from 'react-icons/fa';

import { Link } from '../styles';

type LogoutSettingActionProps = {
  onClick: () => void;
};

const LogoutSettingAction: React.FC<LogoutSettingActionProps> = ({
  onClick,
}) => {
  return (
    <Link to="/login" onClick={onClick}>
      <FaPowerOff size={17} style={{ marginRight: '5px' }} />
      Sair
    </Link>
  );
};

export default LogoutSettingAction;
