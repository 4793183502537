import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';

import { authCreators } from '@store';

import LogoutSettingAction from '@components/dashboard/menu/settings-section/logout';

const LogoutWired: React.FC = () => {
  const dispatch = useDispatch();

  const handleClick = useCallback(() => {
    dispatch(authCreators.signOut());
  }, [dispatch]);
  return <LogoutSettingAction onClick={handleClick} />;
};

export default LogoutWired;
