import styled from 'styled-components';

interface ITitleProps {
  textAlign?: string;
}

export const Title = styled.span<ITitleProps>`
  font-size: 22px;
  font-weight: 600;
  color: ${({ theme }) => theme.text.colors.body};
  ${({ textAlign }) => (textAlign ? `text-align: ${textAlign}` : '')};
`;

interface TextProps {
  padding?: string;
  margin?: string;
  fontSize?: string;
  textAlign?: string;
  width?: string;
  weight?: number;
}

export const Text = styled.span<TextProps>`
  padding: ${({ padding = 0 }) => padding};
  margin: ${({ margin = 0 }) => margin};
  font-size: ${({ fontSize = '16px' }) => fontSize};
  ${({ textAlign }) => (textAlign ? `text-align: ${textAlign}` : '')};
  ${({ width }) => (width ? `width: ${width}` : '')};
  ${({ weight }) => (weight ? `font-weight: ${weight}` : '')};
`;

export const TextField = styled.textarea``;
