import { List } from 'immutable';

export interface IFilterSectionState {
  query: string;
  currentPage: number;
  totalFilteredPessoaJuridica: number;
  fetchedPessoaJuridicaIDs: List<string>;
}

export const INITIAL_STATE: IFilterSectionState = {
  query: '',
  currentPage: 1,
  totalFilteredPessoaJuridica: 0,
  fetchedPessoaJuridicaIDs: List(),
};
