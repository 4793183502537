import React from 'react';
import { BrowserRouter } from 'react-router-dom';

import Routes from './routes';
import ModalGate from './screens/template/modal/index';

function App() {
  return (
    <BrowserRouter>
      <Routes />
      <ModalGate />
    </BrowserRouter>
  );
}

export default App;
