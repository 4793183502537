import styled from 'styled-components';

interface ContainerProps {
  isActive: boolean;
  lastClick: boolean;
}

export const Container = styled.li`
  padding: 10px;
  cursor: pointer;
  padding-left: 5px;
`;

export const IconContainer = styled.span<ContainerProps>`
  padding: 0 5px;
  color: ${({ theme, lastClick, isActive }) =>
    lastClick || isActive ? theme.semantic.primary : '#8d97ad'};
`;
