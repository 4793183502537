import { Record } from 'immutable';

export interface IBanco {
  readonly id: string;
  readonly cod_banco: string;
  readonly nome_banco: string;
}

const defaultValues: IBanco = {
  id: '',
  cod_banco: '',
  nome_banco: '',
};

export interface IBancoRecord extends Record<IBanco>, IBanco {}

export class BancoRecord extends Record(defaultValues) {}
