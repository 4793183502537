import { ApplicationState } from '../..';
import { createSelector } from '../../../class/memoize/createSelector';

const getState = (state: ApplicationState) => state.password.forgot;

const getIsLoading = createSelector([getState], (state) => state.loading);

export default Object.freeze({
  getIsLoading,
});
