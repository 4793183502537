import { makeStyles, createStyles } from '@material-ui/core/styles';
import styled from 'styled-components'

export const useStyles = makeStyles(() =>
  createStyles({
    paper: {
      display: 'flex',
      flexDirection: 'column',
      padding: '15px',
      margin: '0 20px',
    },
    box: {
      display: 'flex',
      marginBottom: '10px',
      justifyContent: 'space-evenly',
    },
    margin: {
      margin: '15px 0',
    },
  }),
);


export const ButtonWrapper = styled.div`
  display: flex;
  justify-content:  flex-start;
  margin: 15px 0;
`;