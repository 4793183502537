import { createSelector } from '../../../class/memoize/createSelector';
import { ApplicationState } from '../../index';

const getState = (state: ApplicationState) => state.core.pessoaFisica;

export const getPessoaFisicaByPessoaFisicaIDs = createSelector(
  [getState],
  (state) => state.pessoaFisicaByPessoaFisicaID,
);

export default Object.freeze({});
