import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      outline: 0,
    },
    content: {
      minWidth: '450px',
      alignItems: 'center',
    },
    margin: {
      margin: theme.spacing(2),
    },
    wrapper: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
  }),
);
