import { Record } from 'immutable';

export interface IUf {
  readonly id: string;
  readonly nome: string;
  readonly nome_abreviado: string;
}

const defaultValues: IUf = {
  id: '',
  nome: '',
  nome_abreviado: '',
};

export interface IUfRecord extends Record<IUf>, IUf {}

export class UfRecord extends Record(defaultValues) {}

export const fromJSON = (json) => {
  const id = json.id !== null ? String(json.id) : defaultValues.id;
  const nome = json.nome !== null ? json.nome : defaultValues.nome;
  const nome_abreviado =
    json.nome_abreviado !== null
      ? json.nome_abreviado
      : defaultValues.nome_abreviado;

  return new UfRecord({
    id,
    nome,
    nome_abreviado,
  });
};
