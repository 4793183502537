import { ApplicationState } from '../index';
import { createSelector } from '../../class/memoize/createSelector';

const getState = (state: ApplicationState) => state.user;

const getLoggedUserId = createSelector([getState], (state) => state.userID);

const getLoggedUserEmail = createSelector(
  [getState],
  (state) => state.userEmail,
);

const getLoggedUserRole = createSelector([getState], (state) => state.role);

export default Object.freeze({
  getLoggedUserId,
  getLoggedUserEmail,
  getLoggedUserRole,
});
