import { IRequestStatus } from './model';

export enum PessoaFisicaFormTypes {
  CLEAR = '@PESSOA_FISICA_FORM/CLEAR',
  SAVE_PESSOA_FISICA_ASYNC = '@PESSOA_FISICA_FORM/SAVE_PESSOA_FISICA_ASYNC',
  SAVE_PESSOA_FISICA_SUCCESS = '@PESSOA_FISICA_FORM/SAVE_PESSOA_FISICA_SUCCESS',
  UPDATE_PESSOA_FISICA_ASYNC = '@PESSOA_FISICA_FORM/UPDATE_PESSOA_FISICA_ASYNC',
  REMOVE_PESSOA_FISICA_ASYNC = '@PESSOA_FISICA_FORM/REMOVE_PESSOA_FISICA_ASYNC',
  SET_PESSOA_FISICA_ID = '@PESSOA_FISICA_FORM/SET_PESSOA_FISICA_ID',
  SET_FINALIZATION_SAVE_STATUS = '@PESSOA_FISICA_FORM/SET_FINALIZATION_SAVE_STATUS',
}

interface ClearAction {
  type: typeof PessoaFisicaFormTypes.CLEAR;
}
interface SavePessoaFisicaSuccessAction {
  type: typeof PessoaFisicaFormTypes.SAVE_PESSOA_FISICA_SUCCESS;
}

export interface SavePessoaFisicaAsyncAction {
  type: typeof PessoaFisicaFormTypes.SAVE_PESSOA_FISICA_ASYNC;
  payload: {
    data: Partial<PessoaFisicaFormDTO>;
  };
}

interface SetPessoaFisicaIDAction {
  type: typeof PessoaFisicaFormTypes.SET_PESSOA_FISICA_ID;
  payload: {
    pessoaFisicaID: string;
  };
}

interface SetFinalizationSaveStatusAction {
  type: typeof PessoaFisicaFormTypes.SET_FINALIZATION_SAVE_STATUS;
  payload: {
    status: IRequestStatus;
  };
}

export interface UpdateRequestAsyncAction {
  type: typeof PessoaFisicaFormTypes.UPDATE_PESSOA_FISICA_ASYNC;
  payload: {
    data: Partial<PessoaFisicaFormDTO>;
    pessoaFisicaID: string;
  };
}

export interface RemoveRequestAsyncAction {
  type: typeof PessoaFisicaFormTypes.REMOVE_PESSOA_FISICA_ASYNC;
  payload: {
    pessoaFisicaID: string;
  };
}

export type PessoaFisicaFormActions =
  | ClearAction
  | SavePessoaFisicaAsyncAction
  | SetPessoaFisicaIDAction
  | SetFinalizationSaveStatusAction
  | UpdateRequestAsyncAction
  | RemoveRequestAsyncAction
  | SavePessoaFisicaSuccessAction;

type TipoCadastroValue =
  | 'tipo_fornecedor'
  | 'tipo_cliente'
  | 'tipo_funcionario';

export type TipoCadastroType = {
  value: TipoCadastroValue;
  label: string;
};

export interface PessoaFisicaFormDTO {
  nome: string;
  data_nasc: Date;
  cpf: string;
  rg?: string;
  orgao_emissor?: string;
  sexo: 'M' | 'F';
  tipo_cadastro: TipoCadastroType[];
  id_banco: {
    label: string;
    value: number | string;
  };
  agencia: string;
  agencia_dv: string;
  conta: string;
  conta_dv: string;
  operacao?: string;
  cep: string;
  logradouro: string;
  numero: string;
  complemento: string;
  cidade: string;
  uf: string;
  email: string;
  ddd: string;
  telefone: string;
  ddd_2?: string;
  telefone_2?: string;
  id_logradouro: number;
  id_uf: number;
  id_cidade: number;
  role: string;
}
