import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

export const Header = styled.div`
  position: fixed;
  width: calc(99% - 230px);
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex: 1;
  padding: 14px 30px;
  margin-bottom: 25px;
  background-color: #fff;
  box-shadow: 1px 1px 10px 1px rgba(0, 0, 0, 0.2);
  z-index: 15;
  margin: 10px;
  border-radius: 5px;
`;
