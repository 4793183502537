import React from 'react';
import { BsPerson } from 'react-icons/bs';

import { Container, Section, Content, Link } from './styles';

interface ISettingsSection {
  isOpen: boolean;
  slotLogoutAction: React.ReactNode;
}

const SettingsSection: React.FC<ISettingsSection> = ({
  isOpen,
  slotLogoutAction,
}) => {
  return (
    <Container isOpen={isOpen} onClick={(e) => e.stopPropagation()}>
      <Section>
        <Content>
          <Link to="/editar-perfil">
            <BsPerson size={20} style={{ marginRight: '5px' }} />
            Meu perfil
          </Link>
        </Content>
      </Section>
      <Content>{slotLogoutAction}</Content>
    </Container>
  );
};

export default SettingsSection;
