export enum PessoaJuridicaBaseTypes {
  CLEAR = '@PESSOA_JURIDICA_BASE/CLEAR',
  FETCH_ASYNC = '@PESSOA_JURIDICA_BASE/FETCH_ASYNC',
  FETCH_SUCCESS = '@PESSOA_JURIDICA_BASE/FETCH_SUCCESS',
  SET_FETCHED_PESSOA_JURIDICA_ID = '@PESSOA_JURIDICA_BASE/SET_FETCHED_PESSOA_JURIDICA_ID',
  FETCH_FAIL = '@PESSOA_JURIDICA_BASE/FETCH_FAIL',
}

// Action types

interface ClearAction {
  type: typeof PessoaJuridicaBaseTypes.CLEAR;
}

export interface FetchAsyncAction {
  type: typeof PessoaJuridicaBaseTypes.FETCH_ASYNC;
  payload: {
    page?: number;
  };
}

interface FetchSuccessAction {
  type: typeof PessoaJuridicaBaseTypes.FETCH_SUCCESS;
  payload: {
    page: number;
    totalFetchedPessoaJuridica: number;
  };
}

interface FetchFailAction {
  type: typeof PessoaJuridicaBaseTypes.FETCH_FAIL;
}

interface SetFetchedPessoaJuridicaIDsAction {
  type: typeof PessoaJuridicaBaseTypes.SET_FETCHED_PESSOA_JURIDICA_ID;
  payload: {
    pessoaJuridicaIDs: ReadonlyArray<string>;
  };
}

export type PessoaJuridicaBaseActions =
  | ClearAction
  | FetchAsyncAction
  | FetchSuccessAction
  | SetFetchedPessoaJuridicaIDsAction
  | FetchFailAction;
