import { List } from 'immutable';

export interface IFilterSectionState {
  query: string;
  currentPage: number;
  totalFilteredPessoaFisica: number;
  fetchedPessoaFisicaIDs: List<string>;
}

export const INITIAL_STATE: IFilterSectionState = {
  query: '',
  currentPage: 1,
  totalFilteredPessoaFisica: 0,
  fetchedPessoaFisicaIDs: List(),
};
