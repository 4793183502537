import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
  margin-bottom: 20px;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  width: 95%;
  background-color: #fff;
  border-radius: 0.2rem;
  padding: 20px;
`;

export const Form = styled.form`
  flex: 1;
`;

export const Section = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  margin-top: 40px;
`;

export const WrapperFormAddressContact = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 50px;
`;

export const ButtonWrapper = styled.div`
  display: flex;
  margin: 15px 0;
`;
