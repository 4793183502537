export enum FilterSectionTypes {
  CLEAR = '@PESSOA_JURIDICA_FILTER_SECTION/CLEAR',
  SET_QUERY = '@PESSOA_JURIDICA_FILTER_SECTION/SET_QUERY',
  FETCH_QUERY_ASYNC = '@PESSOA_JURIDICA_FILTER_SECTION/FETCH_QUERY_ASYNC',
  FETCH_SUCCESS = '@PESSOA_JURIDICA_FILTER_SECTION/FETCH_SUCCESS',
}

// Action Types
interface ClearAction {
  type: typeof FilterSectionTypes.CLEAR;
}
interface SetQueryAction {
  type: typeof FilterSectionTypes.SET_QUERY;
  payload: {
    query: string;
  };
}

export interface FetchQueryAsyncAction {
  type: typeof FilterSectionTypes.FETCH_QUERY_ASYNC;
  payload: {
    query: string;
    page?: number;
  };
}

export interface FetchSuccessAction {
  type: typeof FilterSectionTypes.FETCH_SUCCESS;
  payload: {
    page: number;
    total: number;
    pessoaJuridicaIDs: string[];
  };
}

export type FilterSectionActions =
  | SetQueryAction
  | ClearAction
  | FetchSuccessAction
  | FetchQueryAsyncAction;
