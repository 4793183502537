import { ApplicationState } from '../../index';
import { createSelector } from '../../../class/memoize/createSelector';

const getState = (state: ApplicationState) => state.gcpj.filter;

const getQuery = createSelector([getState], (state) => state.query);

const getCurrentPage = createSelector([getState], (state) => state.currentPage);

const getFetchedFilteredPessoaJuridicaIDs = createSelector(
  [getState],
  (state) => state.fetchedPessoaJuridicaIDs,
);

const getTotalFilteredPessoaJuridica = createSelector(
  [getState],
  (state) => state.totalFilteredPessoaJuridica,
);

export default Object.freeze({
  getQuery,
  getFetchedFilteredPessoaJuridicaIDs,
  getTotalFilteredPessoaJuridica,
  getCurrentPage,
});
