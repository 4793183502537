import { action as Action, createReducer } from 'typesafe-actions';

import { List } from 'immutable';
import { FilterSectionActions, FilterSectionTypes } from './types';
import { IFilterSectionState, INITIAL_STATE } from './model';

export const filterSectionCreator = {
  clear: (): FilterSectionActions => Action(FilterSectionTypes.CLEAR),
  setQuery: (query: string): FilterSectionActions =>
    Action(FilterSectionTypes.SET_QUERY, { query }),
  fetchQueryAsync: (query: string, page?: number): FilterSectionActions =>
    Action(FilterSectionTypes.FETCH_QUERY_ASYNC, { query, page }),
  fetchSuccess: (
    page: number,
    total: number,
    pessoaFisicaIDs: string[],
  ): FilterSectionActions =>
    Action(FilterSectionTypes.FETCH_SUCCESS, { page, total, pessoaFisicaIDs }),
};

const filterSectionReducer = createReducer<
  IFilterSectionState,
  FilterSectionActions
>(INITIAL_STATE)
  .handleType(FilterSectionTypes.CLEAR, () => INITIAL_STATE)
  .handleType(FilterSectionTypes.SET_QUERY, (state, action) => ({
    ...state,
    query: action.payload.query,
  }))
  .handleType(FilterSectionTypes.FETCH_SUCCESS, (state, action) => ({
    ...state,
    currentPage: action.payload.page,
    totalFilteredPessoaFisica: action.payload.total,
    fetchedPessoaFisicaIDs: List(action.payload.pessoaFisicaIDs),
  }));

export default filterSectionReducer;
