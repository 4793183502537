export type IRequestStatus = 'pristine' | 'progress' | 'success' | 'failure';

export interface IPessoaFisicaFormState {
  selectedPessoaFisicaID: string;
  createFinalizationStatus: IRequestStatus;
  isLoading: boolean;
}

export const INITIAL_STATE: IPessoaFisicaFormState = {
  selectedPessoaFisicaID: '',
  createFinalizationStatus: 'pristine',
  isLoading: false,
};
