import React from 'react';

import { Text } from '@components/texts';
import { Container, Header } from './styles';
import ButtonIconMaterial from '../buttons/button-material-icon/index';

import { MdArrowBack } from 'react-icons/md';

import history from '@history';

interface ITemplateScreenProps {
  title: string;
  onClick?: () => void;
  onBack?: boolean;
}

const TemplateScreen: React.FC<ITemplateScreenProps> = ({
  title,
  onClick,
  onBack = false,
  children,
}) => {
  return (
    <Container>
      <Header>
        <Text fontSize="1.125rem" weight={300}>
          {title}
        </Text>
        {onClick && (
          <ButtonIconMaterial
            label="Criar Novo"
            onClick={onClick}
            color="primary"
            variant="contained"
          >
            Cadastrar novo
          </ButtonIconMaterial>
        )}
        {onBack && (
          <ButtonIconMaterial
            label="Voltar"
            onClick={() => history.goBack()}
            icon={<MdArrowBack size={16} />}
            color="primary"
            variant="contained"
          >
            Voltar
          </ButtonIconMaterial>
        )}
      </Header>
      <div style={{ marginTop: '100px' }}>{children}</div>
    </Container>
  );
};

export default TemplateScreen;
