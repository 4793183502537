import { all } from 'redux-saga/effects';
import { combineReducers } from 'redux';

import core from './core';

import { authReducer, authSagas } from './auth';
import { userReducer } from './user';
import { modalReducer } from './modal-gate';
import { default as passwordReducer, passwordSagas } from './password';
import {
  default as pessoaFisicaReducer,
  pessoaFisicaSagas,
} from './pessoa-fisica';
import {
  default as pessoaJuridicaReducer,
  pessoaJuridicaSagas,
} from './pessoa-juridica';

export const reducers = combineReducers({
  core,
  user: userReducer,
  modal: modalReducer,
  auth: authReducer,
  password: passwordReducer,
  gcpf: pessoaFisicaReducer,
  gcpj: pessoaJuridicaReducer,
});

export function* rootSagas() {
  yield all([
    ...authSagas,
    ...passwordSagas,
    ...pessoaFisicaSagas,
    ...pessoaJuridicaSagas,
  ]);
}

// Creators
export { authCreators } from './auth';
export { modalCreators } from './modal-gate';
export { passwordCreators } from './password';
export { pessoaFisicaCreators } from './pessoa-fisica';
export { pessoaJuridicaCreators } from './pessoa-juridica';

// Selectors
export { authSelectors } from './auth';
export { userSelectors } from './user';
export { modalSelectors } from './modal-gate';
export { passwordSelectors } from './password';
export { pessoaFisicaSelectors } from './pessoa-fisica';
export { pessoaJuridicaSelectors } from './pessoa-juridica';

export type ApplicationState = ReturnType<typeof reducers>;
