import React, { useEffect, useState } from 'react';
import TemplateScreen from '@components/template-screen';

import { Controller, useForm } from 'react-hook-form';

import { TextField } from '@material-ui/core';
import { ButtonLoaderMaterial } from '@components/buttons';
import { Text } from '@components/texts';
import { toast } from 'react-toastify';
import { useHistory } from 'react-router-dom';
import { format } from 'date-fns';

import * as yup from 'yup';
import { KeyboardDatePicker } from '@material-ui/pickers';
import { formatNumberEnToBr } from '@utils/format-number-en-to-br';
import { formatNumberBrToEn } from '@utils/format-number-br-to-us';
import CurrencyInput from 'react-currency-input';
import api from '../../../services/api';
import { Container, Content, Form, ButtonWrapper } from './styles';

const validationSchema = yup.object().shape({
  convenio: yup.string().required('Campo requerido'),
  linha: yup.string().required('Campo requerido'),
  inicioVigencia: yup
    .date()
    .required('Campo Requerido')
    .typeError('Data inválida'),
  fimVigencia: yup
    .date()
    .required('Campo Requerido')
    .typeError('Data inválida'),
});

const initialvalues = {
  convenio: '',
  linha: '',
  taxaInicial: '',
  taxaFinal: '',
  prazoInicial: '',
  prazoFinal: '',
  remuneracaoTaxa1: '',
  remuneracaoTaxa2: '',
  inicioVigencia: null,
  fimVigencia: null,
};

interface TaxasProps {
  convenio: string;
  linha: string;
  taxaInicial: string;
  taxaFinal: string;
  prazoInicial: string;
  prazoFinal: string;
  remuneracaoTaxa1: string;
  remuneracaoTaxa2: string;
  inicioVigencia: string | null;
  fimVigencia: string | null;
}

const CadastroEmpresaCDC: React.FC = () => {
  const { errors, control, reset, handleSubmit } = useForm({
    validationSchema,
    defaultValues: initialvalues,
  });
  const urlParams = new URLSearchParams(window.location.search);
  const [isLoading, setIsloading] = useState<boolean>(false);
  const [isEditMode, setIsEditMode] = useState<boolean>(false);
  const history = useHistory();

  useEffect(() => {
    setIsEditMode(!!urlParams.get('id'));

    const loadDataTaxa = async () => {
      if (urlParams.get('id')) {
        const id = urlParams.get('id');

        await api.get(`/cdc-taxas-emp/loadById?id=${id}`).then((response) => {
          const inicioVigencia = response.data.inicio_vigencia
            .toString()
            .split('T')[0];
          const fimVigencia = response.data.fim_vigencia
            .toString()
            .split('T')[0];

          reset({
            convenio: response.data.convenio.convenio_numero,
            linha: response.data.linha,
            taxaInicial: formatNumberEnToBr(response.data.tx_inicial),
            taxaFinal: formatNumberEnToBr(response.data.tx_final),
            prazoInicial: response.data.prazo_inicio,
            prazoFinal: response.data.prazo_fim,
            remuneracaoTaxa1: response.data.rem_taxa_1,
            remuneracaoTaxa2: response.data.rem_taxa_2,
            inicioVigencia,
            fimVigencia,
          });
        });
      }
    };
    loadDataTaxa();
  }, []);

  const onSubmit = async (data: TaxasProps) => {
    try {
      setIsloading(true);

      const inicioVigencia = format(
        new Date(data.inicioVigencia!),
        'yyyy-MM-dd',
      );
      const fimVigencia = format(new Date(data.fimVigencia!), 'yyyy-MM-dd');

      if (isEditMode) {
        await api.post('/cdc-taxas-emp/update', {
          id: urlParams.get('id'),
          convenio: data.convenio,
          linha: data.linha,
          taxaInicial: formatNumberBrToEn(data.taxaInicial),
          taxaFinal: formatNumberBrToEn(data.taxaFinal),
          prazoInicial: data.prazoInicial,
          prazoFinal: data.prazoFinal,
          remuneracaoTaxa1: formatNumberBrToEn(data.remuneracaoTaxa1),
          remuneracaoTaxa2: formatNumberBrToEn(data.remuneracaoTaxa2),
          inicioVigencia,
          fimVigencia,
        });

        toast.success('Taxa atualizada com sucesso!');
      } else {
        await api.post('/cdc-taxas-emp/create', {
          convenio: data.convenio,
          linha: data.linha,
          taxaInicial: formatNumberBrToEn(data.taxaInicial),
          taxaFinal: formatNumberBrToEn(data.taxaFinal),
          prazoInicial: data.prazoInicial,
          prazoFinal: data.prazoFinal,
          remuneracaoTaxa1: formatNumberBrToEn(data.remuneracaoTaxa1),
          remuneracaoTaxa2: formatNumberBrToEn(data.remuneracaoTaxa2),
          inicioVigencia,
          fimVigencia,
        });

        toast.success('Taxa cadastrada com sucesso!');
      }

      history.goBack();
    } catch (e) {
      const error = e as any;
      toast.error(`${error.response.data.message}`);
    } finally {
      setIsloading(false);
    }
  };

  return (
    <>
      <TemplateScreen onBack title="Cadastro remuneração empresa CDC">
        <Container>
          <Content>
            <Text fontSize="1.1rem">Dados cadastrais</Text>
            <Form onSubmit={handleSubmit(onSubmit)}>
              <>
                <Controller
                  as={TextField}
                  control={control}
                  error={!!errors.convenio}
                  helperText={errors.convenio?.message as string}
                  name="convenio"
                  label="Convênio"
                  placeholder="Insira o código do convênio ou nome"
                  fullWidth
                  margin="normal"
                />

                <Controller
                  as={TextField}
                  control={control}
                  error={!!errors.linha}
                  helperText={errors.linha?.message as string}
                  name="linha"
                  label="Linha"
                  placeholder="Insira o número da linha"
                  fullWidth
                  margin="normal"
                />

                <div style={{ display: 'flex' }}>
                  <Controller
                    as={TextField}
                    focused
                    control={control}
                    error={!!errors.taxaInicial}
                    name="taxaInicial"
                    label="Taxa inicial"
                    fullWidth
                    margin="normal"
                    style={{ marginRight: '50px' }}
                    InputProps={{
                      inputComponent: CurrencyInput as any,
                      inputProps: {
                        decimalSeparator: ',',
                        thousandSeparator: '.',
                        precision: 2,
                      },
                    }}
                  />
                  <Controller
                    as={TextField}
                    focused
                    control={control}
                    error={!!errors.taxaFinal}
                    name="taxaFinal"
                    label="Taxa Final"
                    placeholder="Insira a taxa final"
                    fullWidth
                    margin="normal"
                    InputProps={{
                      inputComponent: CurrencyInput as any,
                      inputProps: {
                        decimalSeparator: ',',
                        thousandSeparator: '.',
                        precision: 2,
                      },
                    }}
                  />
                </div>

                <div style={{ display: 'flex' }}>
                  <Controller
                    as={TextField}
                    control={control}
                    error={!!errors.prazoInicial}
                    name="prazoInicial"
                    label="Prazo inicial"
                    placeholder="Insira o prazo inicial"
                    fullWidth
                    margin="normal"
                    style={{ marginRight: '50px' }}
                  />
                  <Controller
                    as={TextField}
                    control={control}
                    error={!!errors.prazoFinal}
                    name="prazoFinal"
                    label="Prazo final"
                    placeholder="Insira o prazo final"
                    fullWidth
                    margin="normal"
                  />
                </div>

                <div style={{ display: 'flex' }}>
                  <Controller
                    as={TextField}
                    focused
                    control={control}
                    error={!!errors.remuneracaoTaxa1}
                    name="remuneracaoTaxa1"
                    label="Remuneracao Taxa 1"
                    placeholder="Insira Taxa 1"
                    fullWidth
                    margin="normal"
                    style={{ marginRight: '50px' }}
                    InputProps={{
                      inputComponent: CurrencyInput as any,
                      inputProps: {
                        decimalSeparator: ',',
                        thousandSeparator: '.',
                        precision: 2,
                      },
                    }}
                  />
                  <Controller
                    as={TextField}
                    focused
                    control={control}
                    error={!!errors.remuneracaoTaxa2}
                    name="remuneracaoTaxa2"
                    label="Remuneracao Taxa 2"
                    placeholder="Insira Taxa 2"
                    fullWidth
                    margin="normal"
                    InputProps={{
                      inputComponent: CurrencyInput as any,
                      inputProps: {
                        decimalSeparator: ',',
                        thousandSeparator: '.',
                        precision: 2,
                      },
                    }}
                  />
                </div>

                <div style={{ display: 'flex' }}>
                  <Controller
                    as={KeyboardDatePicker}
                    control={control}
                    name="inicioVigencia"
                    label="Inicio vigência"
                    fullWidth
                    size="small"
                    margin="normal"
                    format="dd/MM/yyyy"
                    value={{}}
                    invalidDateMessage="Formato de data inválida"
                    error={!!errors.inicioVigencia}
                    helperText={errors.inicioVigencia?.message}
                    style={{ marginRight: '50px' }}
                  />
                  <Controller
                    as={KeyboardDatePicker}
                    control={control}
                    name="fimVigencia"
                    label="Fim vigência"
                    fullWidth
                    size="small"
                    margin="normal"
                    format="dd/MM/yyyy"
                    value={{}}
                    invalidDateMessage="Formato de data inválida"
                    error={!!errors.fimVigencia}
                    helperText={errors.fimVigencia?.message}
                  />
                </div>
              </>
              <ButtonWrapper>
                <ButtonLoaderMaterial
                  type="submit"
                  label="Salvar"
                  variant="contained"
                  color="primary"
                  loading={isLoading}
                />
              </ButtonWrapper>
            </Form>
          </Content>
        </Container>
      </TemplateScreen>
    </>
  );
};

export default CadastroEmpresaCDC;
