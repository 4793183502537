import { Record } from 'immutable';

export interface IPessoaJuridicaTelefone {
  readonly id: string;
  readonly id_gcpj: string;
  readonly ddd: string;
  readonly telefone: string;
  readonly ddd_2?: string;
  readonly telefone_2?: string;
}

const defaultValues: IPessoaJuridicaTelefone = {
  id: '',
  id_gcpj: '',
  ddd: '',
  telefone: '',
};

export interface IPessoaJuridicaTelefoneRecord
  extends Record<IPessoaJuridicaTelefone>,
    IPessoaJuridicaTelefone {}

export class PessoaJuridicaTelefone extends Record(defaultValues) {}
