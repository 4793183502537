import styled from 'styled-components';
import metrics from '../../../../styles/metrics';

interface ContainerProps {
  isOpen: boolean;
}

export const Container = styled.div<ContainerProps>`
  position: fixed;
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: 66px;
  width: ${({ isOpen }) => (isOpen ? 'calc(100% - 230px)' : '100%')};
  margin-left: ${({ isOpen }) => (isOpen ? '230px' : 0)};
  transition: all 0.2s ease;
  min-height: 66px;
  padding: 0 20px;
  background-color: ${({ theme }) => theme.semantic.primary};
  z-index: 15;
`;

export const Content = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex: 1;
`;

export const RightContainer = styled.div`
  @media only screen and (min-width: ${metrics.mobileS}) {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
`;
export const CenterContainer = styled.div`
  @media only screen and (min-width: ${metrics.mobileS}) {
    display: flex;
  }
  @media only screen and (min-width: ${metrics.laptop}) {
    display: none;
  }
`;
export const RightLogo = styled.img`
  object-fit: contain;
  @media only screen and (min-width: ${metrics.mobileS}) {
    display: none;
  }
  @media only screen and (min-width: ${metrics.laptop}) {
    display: block;
    width: 120px;
    height: 50px;
  }
`;

export const MidiLogo = styled.img`
  object-fit: contain;
  @media only screen and (min-width: ${metrics.mobileS}) {
    width: 100px;
    height: 55px;
  }
  @media only screen and (min-width: ${metrics.laptop}) {
    display: none;
  }
`;
export const LeftContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
