import React from 'react';
import SettingsSection from '@components/dashboard/menu/settings-section';
import LogoutWired from './logout-wired';

type SettingsWiredProps = {
  isOpen: boolean;
};

const SettingsWired: React.FC<SettingsWiredProps> = ({ isOpen }) => {
  const slotLogout = <LogoutWired />;

  return <SettingsSection isOpen={isOpen} slotLogoutAction={slotLogout} />;
};

export default SettingsWired;
