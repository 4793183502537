import { call, put, takeLatest } from 'redux-saga/effects';
import { toast } from 'react-toastify';

import { FetchQueryAsyncAction, FilterSectionTypes } from './types';
import { filterSectionCreator } from './duck';
import { fetchPessoaJuridicaByCnpjOrFantasyName } from '../../core/pessoa-juridica/sagas';
import { pessoaJuridicaBaseCreators } from '../base/duck';

function* watchFetchJuridicaByCnpj({ payload }: FetchQueryAsyncAction) {
  try {
    const pessoaFisica = yield call(
      fetchPessoaJuridicaByCnpjOrFantasyName,
      payload.query,
      payload.page,
    );
    const pessoaJuridicaIDs: string[] = pessoaFisica.gcpj.map((gcpj) =>
      String(gcpj.id),
    );
    yield put(
      filterSectionCreator.fetchSuccess(
        pessoaFisica.page,
        pessoaFisica.total,
        pessoaJuridicaIDs,
      ),
    );
  } catch (er) {
    yield put(pessoaJuridicaBaseCreators.fetchFail());
    console.log({ er });
    if (er.response.status === 404) {
      toast.error('Pessoa jurídica não encontrada');
      return;
    }
    toast.error('Falha ao carregar pessoa jurídica');
  }
}

export default [
  takeLatest(FilterSectionTypes.FETCH_QUERY_ASYNC, watchFetchJuridicaByCnpj),
];
