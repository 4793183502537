import { action as Action, createReducer } from 'typesafe-actions';
import { is } from 'immutable';
import { PessoaJuridicaCoreActions, PessoaJuridicaTypes } from './types';
import {
  INITIAL_STATE,
  IPessoaJuridicaState,
  IPessoaJuridicaByPessoaJuridicaID,
  IPessoaJuridicaRecord,
} from './models/pessoa-juridica';
import { normalize } from '../../../class/normalization';

export const pessoaJuridicaCoreCreators = {
  clear: (): PessoaJuridicaCoreActions => Action(PessoaJuridicaTypes.CLEAR),
  add: (pessoaJuridica: IPessoaJuridicaRecord): PessoaJuridicaCoreActions =>
    Action(PessoaJuridicaTypes.ADD, { pessoaJuridica }),
  remove: (pessoaJuridicaID: string): PessoaJuridicaCoreActions =>
    Action(PessoaJuridicaTypes.REMOVE, { pessoaJuridicaID }),
  update: (
    pessoaJuridica: IPessoaJuridicaRecord[],
  ): PessoaJuridicaCoreActions =>
    Action(PessoaJuridicaTypes.UPDATE, { pessoaJuridica }),
};

const pessoaJuridicaCoreReducer = createReducer<
  IPessoaJuridicaState,
  PessoaJuridicaCoreActions
>(INITIAL_STATE)
  .handleType(PessoaJuridicaTypes.CLEAR, () => INITIAL_STATE)
  .handleType(PessoaJuridicaTypes.ADD, (state, action) => ({
    ...state,
    pessoaJuridicaByPessoaJuridicaID: state.pessoaJuridicaByPessoaJuridicaID.set(
      String(action.payload.pessoaJuridica.id),
      action.payload.pessoaJuridica,
    ),
  }))
  .handleType(PessoaJuridicaTypes.UPDATE, (state, action) => {
    const normalized: IPessoaJuridicaByPessoaJuridicaID = normalize(
      action.payload.pessoaJuridica,
    );
    return is(normalized, state.pessoaJuridicaByPessoaJuridicaID)
      ? { ...state }
      : {
          ...state,
          pessoaJuridicaByPessoaJuridicaID: state.pessoaJuridicaByPessoaJuridicaID.merge(
            normalized,
          ),
        };
  })
  .handleType(PessoaJuridicaTypes.REMOVE, (state, action) => ({
    ...state,
    pessoaJuridicaByPessoaJuridicaID: state.pessoaJuridicaByPessoaJuridicaID.remove(
      action.payload.pessoaJuridicaID,
    ),
  }));

export default pessoaJuridicaCoreReducer;
