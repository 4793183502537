import { Record } from 'immutable';

export interface ICidade {
  readonly id: string;
  readonly id_uf: number;
  readonly nome: string;
  readonly codigo: string;
}

const defaultValues: ICidade = {
  id: '',
  id_uf: 0,
  nome: '',
  codigo: '',
};

export interface ICidadeRecord extends Record<ICidade>, ICidade {}

export class CidadeRecord extends Record(defaultValues) {}
