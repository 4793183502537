import { action as Action, createReducer } from 'typesafe-actions';
import {
  PessoaJuridicaFormActions,
  PessoaJuridicaFormTypes,
  PessoaJuridicaFormDTO,
} from './types';
import {
  INITIAL_STATE,
  IPessoaJuridicaFormState,
  IRequestStatus,
} from './model';

export const pessoaJuridicaFormCreators = {
  clear: (): PessoaJuridicaFormActions => Action(PessoaJuridicaFormTypes.CLEAR),
  savePessoaJuridicaAsync: (
    data: Partial<PessoaJuridicaFormDTO>,
  ): PessoaJuridicaFormActions =>
    Action(PessoaJuridicaFormTypes.SAVE_PESSOA_JURIDICA_ASYNC, { data }),
  savePessoaJuridicaSuccess: (): PessoaJuridicaFormActions =>
    Action(PessoaJuridicaFormTypes.SAVE_PESSOA_JURIDICA_SUCCESS),
  updatePessoaJuridicaAsync: (
    data: Partial<PessoaJuridicaFormDTO>,
    pessoaJuridicaID: string,
  ): PessoaJuridicaFormActions =>
    Action(PessoaJuridicaFormTypes.UPDATE_PESSOA_JURIDICA_ASYNC, {
      data,
      pessoaJuridicaID,
    }),
  removePessoaJuridicaAsync: (
    pessoaJuridicaID: string,
  ): PessoaJuridicaFormActions =>
    Action(PessoaJuridicaFormTypes.REMOVE_PESSOA_JURIDICA_ASYNC, {
      pessoaJuridicaID,
    }),
  setPessoaJuridicaID: (pessoaJuridicaID: string): PessoaJuridicaFormActions =>
    Action(PessoaJuridicaFormTypes.SET_PESSOA_JURIDICA_ID, {
      pessoaJuridicaID,
    }),
  requestFinished: (status: IRequestStatus): PessoaJuridicaFormActions =>
    Action(PessoaJuridicaFormTypes.SET_FINALIZATION_SAVE_STATUS, { status }),
};

const pessoaJuridicaFormReducer = createReducer<
  IPessoaJuridicaFormState,
  PessoaJuridicaFormActions
>(INITIAL_STATE)
  .handleType(PessoaJuridicaFormTypes.CLEAR, () => INITIAL_STATE)
  .handleType(PessoaJuridicaFormTypes.SAVE_PESSOA_JURIDICA_ASYNC, (state) => ({
    ...state,
    isLoading: true,
    createFinalizationStatus: 'progress',
  }))
  .handleType(
    PessoaJuridicaFormTypes.UPDATE_PESSOA_JURIDICA_ASYNC,
    (state) => ({
      ...state,
      isLoading: true,
    }),
  )
  .handleType(
    PessoaJuridicaFormTypes.REMOVE_PESSOA_JURIDICA_ASYNC,
    (state) => ({
      ...state,
      isLoading: true,
    }),
  )
  .handleType(
    PessoaJuridicaFormTypes.SET_FINALIZATION_SAVE_STATUS,
    (state, action) => ({
      ...state,
      status: action.payload.status,
      isLoading: false,
    }),
  )
  .handleType(
    PessoaJuridicaFormTypes.SET_PESSOA_JURIDICA_ID,
    (state, action) => ({
      ...state,
      selectedPessoaJuridicaID: action.payload.pessoaJuridicaID,
    }),
  )
  .handleType(
    PessoaJuridicaFormTypes.SAVE_PESSOA_JURIDICA_SUCCESS,
    (state) => ({
      ...state,
      isLoading: false,
      createFinalizationStatus: 'success',
    }),
  );

export default pessoaJuridicaFormReducer;
