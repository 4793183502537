import React from 'react';
import { Map } from 'immutable';

import { useTheme } from '@hooks';

import { Container } from './styles';

interface ButtonProps {
  label: string;
  type?: string;
  onClick(e: React.MouseEvent): void;
  bgColor?:
    | 'primary'
    | 'success'
    | 'info'
    | 'warning'
    | 'danger'
    | 'transparent';
  color?: string;
  className?: string;
  width?: string;
  height?: string;
}

const Button: React.FC<ButtonProps> = ({
  label,
  onClick,
  type = 'button',
  bgColor,
  color,
  className,
  width,
  height,
}) => {
  const theme = useTheme();
  const BackgroundColorMap = Map({
    primary: theme.colors.primaryMain,
    danger: theme.colors.dangerMain,
    success: theme.colors.successMain,
    info: theme.colors.infoMain,
    warning: theme.colors.warningMain,
    transparent: 'transparent',
  });
  const backgroundColor = bgColor && BackgroundColorMap.get(bgColor, false);

  return (
    <Container
      className={className}
      onClick={onClick}
      type={type}
      bgColor={backgroundColor}
      color={color}
      width={width}
      height={height}
    >
      {label}
    </Container>
  );
};

export default Button;
