export enum CreatePasswordTypes {
  REQUEST_ASYNC = '@CREATE_PASSWORD/REQUEST_ASYNC',
  REQUEST_SUCCESS = '@CREATE_PASSWORD/REQUEST_SUCCESS',
  REQUEST_FAIL = '@CREATE_PASSWORD/REQUEST_FAIL',
}

// Action Types

export interface RequestAsyncAction {
  type: typeof CreatePasswordTypes.REQUEST_ASYNC;
  payload: {
    token: string;
    password: string;
  };
}

interface RequestSuccessAction {
  type: typeof CreatePasswordTypes.REQUEST_SUCCESS;
}

interface RequestFailAction {
  type: typeof CreatePasswordTypes.REQUEST_FAIL;
}

export type CreatePasswordActions =
  | RequestAsyncAction
  | RequestSuccessAction
  | RequestFailAction;
