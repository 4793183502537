import { ApplicationState } from '../..';
import { createSelector } from '../../../class/memoize/createSelector';
import { getPessoaJuridicaByPessoaJuridicaIDs } from '../../core/pessoa-juridica/selectors';
import { TipoCadastroType } from './types';

const getState = (state: ApplicationState) => state.gcpj.form;

const getIsLoading = createSelector([getState], (state) => state.isLoading);

const getFinalizationStatus = createSelector(
  [getState],
  (state) => state.createFinalizationStatus,
);

const getSelectedPessoaJuridicaID = createSelector(
  [getState],
  (state) => state.selectedPessoaJuridicaID,
);

const getPessoaJuridicaFormDTOByPessoaJuridicaID = createSelector(
  [getPessoaJuridicaByPessoaJuridicaIDs],
  (pessoaJuridicaByPessoaJuridicaIDs) =>
    pessoaJuridicaByPessoaJuridicaIDs.map((pessoaJuridica) => {
      const tipo_cadastro: TipoCadastroType[] = [];
      if (pessoaJuridica.fornecedor) {
        tipo_cadastro.push({
          value: 'fornecedor',
          label: 'Tipo Fornecedor',
        });
      }
      if (pessoaJuridica.cliente) {
        tipo_cadastro.push({
          value: 'cliente',
          label: 'Tipo cliente',
        });
      }
      if (pessoaJuridica.parceiro) {
        tipo_cadastro.push({
          value: 'parceiro',
          label: 'Tipo Parceiro',
        });
      }
      return {
        nome_fantasia: pessoaJuridica.nome_fantasia,
        razao_social: pessoaJuridica.razao_social,
        dt_abertura: pessoaJuridica.dt_abertura,
        cnpj: pessoaJuridica.cnpj,
        ie: pessoaJuridica.ie,
        ie_isenta: pessoaJuridica.ie_isenta,
        tipo_cadastro,
        id_banco: {
          value: pessoaJuridica.conta.banco.id,
          label: pessoaJuridica.conta.banco.nome_banco,
        },
        agencia: pessoaJuridica.conta.agencia,
        agencia_dv: pessoaJuridica.conta.agencia_dv,
        conta: pessoaJuridica.conta.conta,
        conta_dv: pessoaJuridica.conta.conta_dv,
        operacao: pessoaJuridica.conta.operacao || '',
        cep: pessoaJuridica.endereco.logradouro.cep,
        logradouro: pessoaJuridica.endereco.logradouro.logradouro,
        numero: pessoaJuridica.endereco.numero,
        complemento: pessoaJuridica.endereco.complemento,
        cidade: pessoaJuridica.endereco.cidade.nome,
        uf: pessoaJuridica.endereco.uf.nome,
        email: pessoaJuridica.email.email,
        ddd: pessoaJuridica.telefone.ddd,
        telefone: pessoaJuridica.telefone.telefone,
        ddd_2: pessoaJuridica.telefone.ddd_2,
        telefone_2: pessoaJuridica.telefone.telefone_2,
        id_logradouro: Number(pessoaJuridica.endereco.logradouro.id),
        id_uf: Number(pessoaJuridica.endereco.uf.id),
        id_cidade: Number(pessoaJuridica.endereco.cidade.id),
      };
    }),
);

export default Object.freeze({
  getIsLoading,
  getFinalizationStatus,
  getSelectedPessoaJuridicaID,
  getPessoaJuridicaFormDTOByPessoaJuridicaID,
});
